import { SaveRegular } from "@fluentui/react-icons";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex, ProfileContentWrap } from "components/styled";
import { Form, Formik } from "formik";
import useEmployee from "hooks/useEmployee";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import { EProfileTab, ETaxonomy, IEmployee } from "library/types";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUpdateEmployee } from "state/useUpdateEmployee";
import { selectedClientAtom } from "store";
import { selectedProfileTabAtom } from "store/UIProfilePage";
import * as yup from "yup";
import { BasicFields } from "../BasicFields";
import { MainFields } from "../MainFields";
import { MainConsentField } from "./MainConsentField";
import { useGetConsentLink } from "./hooks/useGetConsentLink";

export function EmployeeForm() {
  const { employeeData } = useEmployee();

  const { t } = useTranslation();

  const selectedTab = useAtomValue(selectedProfileTabAtom);

  const selectedClient = useAtomValue(selectedClientAtom);

  const consentLink = useGetConsentLink();

  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);
  const { mutate: onUpdateEmployee, isLoading: updateEmployeeInProgress } =
    useUpdateEmployee(false);

  const handleSubmit = useCallback(
    (values) => {
      onUpdateEmployee(
        {
          countryCode: Number(selectedClient?.countryCode),
          clientInternalNumberGOS: Number(
            selectedClient?.clientInternalNumberGos
          ),
          payload: {
            ...values,
            genderCodeId: formatSelectFieldForPayload({
              values,
              field: "genderCodeId",
            }),
            citizenshipCodeId: formatSelectFieldForPayload({
              values,
              field: "citizenshipCodeId",
            }),

            dateOfBirth: formatDateFieldForPayload(values.dateOfBirth),
            ...(values.employeeCoverPhoto?.base64String && {
              backgroundPictureContent: values.employeeCoverPhoto?.base64String,
              backgroundPictureName: values.employeeCoverPhoto?.name,
              backgroundPicturePath: values.employeeCoverPhoto?.path,
              backgroundPictureMimeType: values.employeeCoverPhoto?.type,
            }),

            backgroundPicturePath: values.employeeCoverPhoto?.path,
            dependents: values.dependents.map((dependent) => {
              return {
                ...dependent,
                rowVersion: dependent.rowVersion || employeeData?.rowVersion,
              };
            }),
          },
        },
        {
          onSuccess() {
            toast.success(t("hbh.employeeUpdatedSuccessfully.label"));
          },
        }
      );
    },
    [selectedClient, employeeData]
  );

  const initialValues = useMemo(() => {
    return {
      //main
      ...employeeData,
      consentText: consentLink,
      dependents:
        (employeeData?.dependents as IEmployee["dependents"])?.map(
          (dependent) => {
            return {
              ...dependent,
              dependentCoverPhoto: dependent?.backgroundPicturePath
                ? {
                    name: dependent?.backgroundPictureName,
                    path: dependent?.backgroundPicturePath,
                    base64String: dependent?.backgroundPictureContent,
                    type: dependent?.backgroundPictureMimeType,
                  }
                : null,
            };
          }
        ) || [],

      //basic
      genderCodeId: genderOptions.find(
        (genderCode) => genderCode.value === employeeData?.genderCodeId
      ),
      citizenshipCodeId: countryOptions.find(
        (country) => country.value === employeeData?.citizenshipCodeId
      ),

      employeeCoverPhoto: employeeData?.backgroundPicturePath
        ? {
            name: employeeData?.backgroundPictureName,
            path: employeeData?.backgroundPicturePath,
            base64String: employeeData?.backgroundPictureContent,
            type: employeeData?.backgroundPictureMimeType,
          }
        : null,
    };
  }, [employeeData, genderOptions, countryOptions]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      emailAddress: yup.string().required(),
      mobilePhoneNumber: yup.string().required(),
      dateOfBirth: yup.string().required(),
      isConsentGiven: yup
        .boolean()
        .oneOf([true], t("hbh.mustAcceptConsent.label"))
        .required(),
      dependents: yup.array().of(
        yup.object().shape({
          dependentType: yup.mixed().required(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
        })
      ),
    });
  }, []);

  if (!employeeData) {
    return <AppLogoSpinner />;
  }
  return (
    <>
      {updateEmployeeInProgress && <AppLogoSpinner />}

      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({ errors }) => {
          return (
            <Form>
              <Flex direction="column">
                {selectedTab === EProfileTab.info && (
                  <ProfileContentWrap>
                    <MainFields isAddMode={false} appearance="filled-darker" />
                    <BasicFields appearance="filled-darker" />
                    {employeeData?.isConsentGiven ? null : <MainConsentField />}
                    {/* <ManageEmployeeDocuments item={employeeData} /> */}
                    <PrimaryButton
                      icon={<SaveRegular />}
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                        marginLeft: "auto",
                        width: "fit-content",
                      }}
                    >
                      {t("greco.save")}
                    </PrimaryButton>
                  </ProfileContentWrap>
                )}
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
