import { Button } from "@fluentui/react-components";
import {
  GiftOpenRegular,
  GiftRegular,
  MegaphoneLoudRegular,
} from "@fluentui/react-icons";
import { ChatIcon } from "components/ChatPanel/ChatIcon";
import { SelectClient } from "components/SelectClient/SelectClient";
import { useAppTheme } from "hooks/useAppTheme";
import useEmployee from "hooks/useEmployee";
import { useAtom, useSetAtom } from "jotai";
import { devices, ROUTES, sizes } from "library/constants";
import { EBenefitContentTab } from "library/types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { chatPanelOpenAtom } from "store";
import { benefitContentTabAtom } from "store/UIBenefitsPage";
import styled from "styled-components";

export function AppNavigation() {
  const { employeeData } = useEmployee();

  const { pathname } = useLocation();
  const setChatPanelOpen = useSetAtom(chatPanelOpenAtom);

  const [selectedContentTab, setSelectedContentTab] = useAtom(
    benefitContentTabAtom
  );

  const isGivenConsent = employeeData?.isConsentGiven;

  const theme = useAppTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToPage = (page, state = {}) => {
    navigate(page, { state });
  };

  return (
    <>
      <Container>
        <NavItem
          onClick={() => {
            goToPage(ROUTES.BENEFITS, {
              selectedContentTab,
            });
            setSelectedContentTab(EBenefitContentTab.enrolled);
          }}
        >
          <StyledButton
            disabled={!isGivenConsent}
            size="large"
            appearance="subtle"
            $itemColor={theme.palette.themePrimary}
            $active={
              selectedContentTab === EBenefitContentTab.enrolled &&
              ROUTES.BENEFITS === pathname &&
              isGivenConsent
            }
            icon={
              <GiftOpenRegular
                style={{
                  borderRadius: "50%",
                  padding: "1px",
                  ...(isGivenConsent
                    ? {
                        color: theme.palette.white,
                        background: theme.palette.themePrimary,
                      }
                    : {
                        color: theme.palette.white, //disabled color
                        background: theme.palette.neutralLight, //disabled color
                      }),
                }}
              />
            }
            name="enrolled"
          >
            {t("hbh.enrolled.label")}
          </StyledButton>
        </NavItem>

        <NavItem
          onClick={() => {
            goToPage(ROUTES.BENEFITS, {
              selectedContentTab,
            });
            setSelectedContentTab(EBenefitContentTab.eligible);
          }}
        >
          <StyledButton
            disabled={!isGivenConsent}
            size="large"
            appearance="subtle"
            $itemColor={theme.palette.green}
            $active={
              selectedContentTab === EBenefitContentTab.eligible &&
              ROUTES.BENEFITS === pathname
            }
            icon={
              <GiftRegular
                style={{
                  borderRadius: "50%",
                  padding: "1px",
                  ...(isGivenConsent
                    ? {
                        color: theme.palette.white,
                        background: theme.palette.green,
                      }
                    : {
                        color: theme.palette.white, //disabled color
                        background: theme.palette.neutralLight, //disabled color
                      }),
                }}
              />
            }
          >
            {t("hbh.eligible.label")}
          </StyledButton>
        </NavItem>
        <NavItem
          onClick={() => {
            goToPage(ROUTES.OFFERINGS, {
              selectedContentTab,
            });
          }}
        >
          <StyledButton
            disabled
            size="large"
            appearance="subtle"
            $itemColor={theme.palette.magenta}
            $active={ROUTES.OFFERINGS === pathname}
            icon={
              <MegaphoneLoudRegular
                style={{
                  borderRadius: "50%",
                  padding: "1px",
                  // color: theme.palette.white,
                  // background: theme.palette.magenta,
                  color: theme.palette.white,
                  background: theme.palette.neutralLight,
                }}
              />
            }
          >
            {t("hbh.offerings.label")}
          </StyledButton>
        </NavItem>
        <SelectClient onlyDesktop />

        <NavItem
          onClick={() => {
            setChatPanelOpen(true);
          }}
          $autoMl
        >
          <StyledButton
            disabled={!isGivenConsent}
            size="large"
            $itemColor={theme.palette.orangeLighter}
            appearance="subtle"
            $active={false}
            icon={
              <ChatIcon
                style={{
                  width: "24px",
                  height: "24px",
                  ...(isGivenConsent
                    ? {}
                    : {
                        color: theme.palette.white, //disabled color
                        background: theme.palette.neutralLight, //disabled color
                      }),
                }}
              />
            }
          >
            {t("hbh.askQuestion.label")}
          </StyledButton>
        </NavItem>
      </Container>
    </>
  );
}

const Container = styled.div`
  background: ${({ theme }) => theme.palette.white};
  background-color: ${(props) => props.theme.palette.white};
  border-top: 1px solid ${(props) => props.theme.palette.neutralLight};
  box-shadow: 0px -4px 6px -2px ${(props) => props.theme.palette.neutralLight};

  display: flex;
  box-sizing: border-box;
  gap: 5px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  justify-content: space-around;
  padding: 5px;
  height: ${sizes.appNavHeightMobile}px;

  @media only screen and ${devices.md} {
    height: ${sizes.appNavHeightDesktop}px;
    position: relative;
    box-shadow: 0px 4px 6px -2px ${(props) => props.theme.palette.neutralLight};
    gap: 20px;
    justify-content: flex-start;
    padding: 0 40px;
  }
`;

const StyledButton = styled(Button)<{ $active?: boolean; $itemColor?: string }>`
  display: flex;
  align-items: center;
  gap: 0;
  flex-direction: column;
  font-weight: 600;
  font-size: 10px;

  min-width: 70px;

  .fui-Button__icon {
    width: 26px;
    height: 24px;
    margin-right: 0;
  }

  &:not(:disabled) {
    color: ${({ theme, $itemColor, $active }) =>
      $active ? $itemColor : theme.palette.black};
  }

  text-decoration: ${({ theme, $itemColor, $active }) =>
    $active ? "underline" : "none"};

  @media only screen and ${devices.xs2} {
    font-size: 12px;
  }

  @media only screen and ${devices.md} {
    flex-direction: row;
    gap: 5px;
    font-size: 14px;
  }
`;

// border: ${({ theme, $active }) =>
//   `1px solid ${$active ? theme.palette.themePrimary : "transparent"}`};
// color: ${({ theme, $itemColor, $active }) =>
//   $active ? theme.palette.white : $itemColor};
// background: ${({ theme, $itemColor, $active }) =>
//   $active ? $itemColor : theme.palette.white};

const NavItem = styled.div<{ $autoMl?: boolean }>`
  display: flex;
  gap: 5px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  @media only screen and ${devices.md} {
    margin-left: ${({ $autoMl }) => ($autoMl ? "auto" : "0")};
    flex-direction: row;
  }
`;
