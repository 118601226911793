import { Button, Link, Text } from "@fluentui/react-components";
import { DownloadDocumentIcon } from "@fluentui/react-icons-mdl2";
import CheckboxField from "components/controls/CheckboxField";
import { Flex, Grid } from "components/styled";
import { useFormikContext } from "formik";
import { EInsurerConsentType, IConsentDeliveryType } from "library/types";
import { extractFileNameFromUrl, openLinkInNewTab } from "library/utils";
import { useTranslation } from "react-i18next";
import { UploadConsentDocuments } from "./UploadConsentDocuments";

type Props = {
  isUpgradeEnroll?: boolean;
};

export function ConsentDependencyFields({ isUpgradeEnroll }: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormData>();

  const selectedInsurerConsentType = values["insurerConsentType"];

  const urlToInsurerConsentType =
    selectedInsurerConsentType?.value === EInsurerConsentType.URL_TO_INSURER;

  const insurerFormConsentType =
    selectedInsurerConsentType?.value === EInsurerConsentType.INSURER_FORM;
  const iAgreeTextConsentType =
    selectedInsurerConsentType?.value === EInsurerConsentType.I_AGREE_TEXT;

  const insurerFormConsentDeliveryType = insurerFormConsentType
    ? `(${values["insurerConsentDeliveryType"]?.text})`
    : "";

  const isInsurerFormConsentDeliveryTypeUploadScan =
    insurerFormConsentType &&
    values["insurerConsentDeliveryType"]?.code ===
      IConsentDeliveryType.UploadScan;

  const isInsurerFormConsentDeliveryTypePaperToGreco =
    insurerFormConsentType &&
    values["insurerConsentDeliveryType"]?.code ===
      IConsentDeliveryType.PaperToGreco;

  const isInsurerFormConsentDeliveryTypePaperToInsurer =
    insurerFormConsentType &&
    values["insurerConsentDeliveryType"]?.code ===
      IConsentDeliveryType.PaperToInsurer;

  const sendConsentText = t("hbh.sendConsentToObject.label", {
    object: isInsurerFormConsentDeliveryTypePaperToGreco
      ? t("hbh.greco.label")
      : isInsurerFormConsentDeliveryTypePaperToInsurer
      ? t("hbh.insurer.label")
      : "",
  });

  if (!selectedInsurerConsentType) {
    return null;
  }

  return (
    <>
      <Flex $gap={"10px"}>
        <Text weight="semibold" size={400}>{`${t(
          "hbh.typeOfConsentInsurer.label"
        )} - ${
          values["insurerConsentType"]?.text
        } ${insurerFormConsentDeliveryType}`}</Text>
      </Flex>

      {urlToInsurerConsentType && (
        <Grid gridColumns={1}>
          <Link
            href={values["insurerConsentUrl"]}
            style={{
              marginBottom: "20px",
            }}
            target="_blank"
          >
            {values["insurerConsentUrl"]}
          </Link>
        </Grid>
      )}
      {insurerFormConsentType && (
        <Grid gridColumns={1}>
          <Button
            style={{
              justifyContent: "flex-start",
              gap: "5px",
            }}
            appearance="transparent"
            icon={<DownloadDocumentIcon />}
            onClick={() => {
              openLinkInNewTab(values["insurerConsentDocumentPath"]);
            }}
          >
            {extractFileNameFromUrl(values["insurerConsentDocumentPath"])}{" "}
            {`- ${t("hbh.download.label")}`}
          </Button>

          {isInsurerFormConsentDeliveryTypeUploadScan ? (
            // <UploadFile
            //   setFieldValue={setFieldValue}
            //   fieldName="scannedSignedConsentDocument"
            //   label={t("hbh.uploadSignedConsentType.label")}
            // />
            <UploadConsentDocuments />
          ) : (
            <Text>{sendConsentText}</Text>
          )}
        </Grid>
      )}
      {iAgreeTextConsentType && (
        <Grid gridColumns={1}>
          <Text>
            <q>{values["insurerConsentText"]}</q>
          </Text>
          <CheckboxField
            name={"iAgreeTextConsent"}
            value={values["iAgreeTextConsent"]}
            setFieldValue={setFieldValue}
            label={t("hbh.iAgreeToThisText.label")}
          />
        </Grid>
      )}
    </>
  );
}
