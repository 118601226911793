import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IAttachment, IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  attachmentName: string;
  payload: IAttachment;
};
const updateAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  attachmentName,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employee/attachment/${countryCode}/${clientInternalNumberGOS}/${attachmentName}`,
    payload
  );
  return response.data as IAttachment;
};
export function useUpdateEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();

  const invalidateEmployee = useInvalidateEmployeeData();

  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(updateAttachment, {
    mutationKey: MUTATION_KEYS.updateEmployeeAttachment,
    onSuccess: (updatedAttachment) => {
      if (refetch) {
        invalidateEmployee();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeData,
          updatedAttachment?.countryCode?.toString(),
          updatedAttachment?.clientInternalNumberGos?.toString(),
        ]);

        const updatedEmployeeData = {
          ...previousEmployeeData,
          attachments: (previousEmployeeData?.attachments || []).map(
            (attach) => {
              if (attach.attachmentName === updatedAttachment.attachmentName) {
                return updatedAttachment;
              }
              return attach;
            }
          ),
        };
        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeData,
            updatedAttachment?.countryCode?.toString(),
            updatedAttachment?.clientInternalNumberGos?.toString(),
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
