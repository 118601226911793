import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import { consentLinksByCountries } from "library/constants";
import { ETaxonomy } from "library/types";
import { selectedClientAtom } from "store";

export function useGetConsentLink() {
  const selectedClient = useAtomValue(selectedClientAtom);

  const taxonomies = useTaxonomyOptions(ETaxonomy.Country);

  const clientCountry = selectedClient?.countryCode;
  const countryCode = taxonomies.find(
    (item) => item.value === clientCountry
  )?.code;

  const countryConsentLink = consentLinksByCountries[countryCode];

  return countryConsentLink?.length
    ? countryConsentLink[0]
    : consentLinksByCountries["Country.AT"][0];
}
