import { useAtomValue } from "jotai";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  styled,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import "./App.css";
import AppHeader from "./components/AppHeader/AppHeader";

import {
  darkModeAtom,
  panelIsOpenState,
  selectedClientAtom,
  selectedFullThemeAtom,
} from "./store";

import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";

import { useEffect, useState } from "react";

import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { AppNavigation } from "components/AppNavigation/AppNavigation";
import { ConsentGuardedRoute } from "components/ConsentGuardedRoute/ConsentGuardedRoute";
import { useChatSessionId } from "hooks/useChatSessionId";
import useEmployee from "hooks/useEmployee";
import { useInitSelectClient } from "hooks/useInitSelectClient";
import { useResizeObserverDevErrorFix } from "hooks/useResizeObserverDevErrorFix";
import { devices, ROUTES, sizes } from "library/constants";
import { BenefitsPage } from "pages/BenefitsPage/BenefitsPage";
import { HomePage } from "pages/HomePage/HomePage";
import { NoClientPage } from "pages/NoClientPage/NoClientPage";
import { ProfilePage } from "pages/ProfilePage/ProfilePage";
import { useGetTaxonomies } from "state/useGetTaxonomies";
import { IStyledTheme } from "theme/types";
import grecoTriangleBg from "./assets/GrECoGraphicTriangles.svg";
import { PaletteDemo } from "pages/PaletteDemo/PaletteDemo";

function App() {
  useResizeObserverDevErrorFix();
  useInitSelectClient();
  useGetTaxonomies();
  useChatSessionId();

  // const { data: mainConsentApproved } = useIsMainConsentApproved({
  //   countryCode: 0,
  //   clientInternalNumberGos: 0,
  //   enabled: true,
  // });
  useEmployee();

  const darkMode = useAtomValue(darkModeAtom);

  const selectedTheme = useAtomValue(selectedFullThemeAtom);

  const [appTheme, setAppTheme] = useState<IStyledTheme | null>(null);

  const [fluentTheme, setFluentTheme] = useState(
    darkMode ? webDarkTheme : webLightTheme
  );

  useEffect(() => {
    const fluentTheme = darkMode ? webDarkTheme : webLightTheme;
    setAppTheme(darkMode ? selectedTheme?.dark : selectedTheme?.light);
    setFluentTheme(fluentTheme);
  }, [darkMode, selectedTheme]);

  if (!appTheme) return;

  return (
    <div className="App">
      <FluentProvider theme={fluentTheme}>
        <StyledThemeProvider theme={appTheme}>
          <AppContent />
        </StyledThemeProvider>
      </FluentProvider>
    </div>
  );
}

export default App;

const AppContent = () => {
  const panelIsOpen = useAtomValue(panelIsOpenState);
  const selectedClient = useAtomValue(selectedClientAtom);
  const userWithoutClients = selectedClient === null;
  const { employeeData } = useEmployee();

  return (
    <BrowserRouter>
      {selectedClient === undefined && <AppLogoSpinner />}

      <AppHeader />
      {userWithoutClients ? (
        <NoClientPage />
      ) : employeeData ? (
        <>
          <AppNavigation />
          <Container $isPanelOpen={panelIsOpen}>
            <Routes>
              <Route
                path={ROUTES.HOME}
                element={
                  <ConsentGuardedRoute path={ROUTES.HOME}>
                    <HomePage />
                  </ConsentGuardedRoute>
                }
              />
              <Route path={ROUTES.PROFILE} element={<ProfilePage />} />

              <Route
                path={ROUTES.BENEFITS}
                element={
                  <ConsentGuardedRoute path={ROUTES.BENEFITS}>
                    <BenefitsPage />
                  </ConsentGuardedRoute>
                }
              />
              <Route
                path={"/palette-demo"}
                element={
                  <ConsentGuardedRoute path={"/palette-demo"}>
                    <PaletteDemo />
                  </ConsentGuardedRoute>
                }
              />
              <Route
                path={"*"}
                element={
                  <ConsentGuardedRoute path={"*"}>
                    <Navigate to={ROUTES.BENEFITS} replace />
                  </ConsentGuardedRoute>
                }
              />
            </Routes>
          </Container>
        </>
      ) : (
        <AppLogoSpinner />
      )}
      <ToastContainer
        style={{
          zIndex: 9999999,
        }}
      />
    </BrowserRouter>
  );
};

const Container = styled("div")<{ $isPanelOpen: boolean }>`
  position: absolute;
  overflow: auto;
  top: ${sizes.appHeader}px; //50px header
  left: 0;
  bottom: ${sizes.appNavHeightMobile}px; //footer
  right: 0;
  // width: ${({ $isPanelOpen }) =>
    $isPanelOpen ? "calc(100% - 340px)" : "100%"};
  width: 100%;
  background-color: ${(props) => props.theme.palette.themeBackground};

  background-image: url("${grecoTriangleBg}");
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;

  @media only screen and ${devices.md} {
    top: ${sizes.appHeader +
    sizes.appNavHeightDesktop}px; //50px header  + 40px navigation
    bottom: 0;
  }
`;
