import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";
import { IEmployee } from "library/types";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  attachmentName: string;
  rowVersion: string;
};

const deleteAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  attachmentName,
  rowVersion,
}: Params) => {
  const response = await api.patch(
    `/employee/attachment/delete/${countryCode}/${clientInternalNumberGOS}/${attachmentName}`,
    {
      rowVersion,
    }
  );
  return response.data;
};

export function useDeleteEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateEmployee = useInvalidateEmployeeData();

  return useMutation(deleteAttachment, {
    mutationKey: MUTATION_KEYS.deleteEmployeeAttachment,
    onSuccess: (deletedAttach) => {
      if (refetch) {
        invalidateEmployee();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeData,
          deletedAttach?.countryCode?.toString(),
          deletedAttach?.clientInternalNumberGos?.toString(),
        ]);
        const updatedEmployeeData = {
          ...previousEmployeeData,
          attachments: (previousEmployeeData?.attachments || []).filter(
            (attach) => {
              return attach.attachmentName !== deletedAttach.attachmentName;
            }
          ),
        };
        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeData,
            deletedAttach?.countryCode?.toString(),
            deletedAttach?.clientInternalNumberGos?.toString(),
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
