import {
  Button,
  Dialog,
  DialogActions,
  DialogSurface,
} from "@fluentui/react-components";
import { GiftOpenRegular, RocketRegular } from "@fluentui/react-icons";
import { CancelIcon } from "@fluentui/react-icons-mdl2";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { Form, Formik } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { devices } from "library/constants";
import {
  EAttachmentType,
  EInsurerConsentType,
  ETaxonomy,
  ICardBenefitPackage,
  IConsentDeliveryType,
} from "library/types";
import { formatDateFieldForPayload } from "library/utils";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useEnrollPackage } from "state/useEnrollPackage";
import { useUpgradeEnrollment } from "state/useUpgradeEnrollment";
import styled from "styled-components";
import * as yup from "yup";
import { EnrollPackageFormItems } from "./EnrollPackageFormItems";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  benefitPackage: ICardBenefitPackage;
  isUpgradeEnroll?: boolean;
};

export function EnrollPackageDialog({
  open,
  setOpen,
  benefitPackage,
  isUpgradeEnroll,
}: Props) {
  const { t } = useTranslation();
  const consentAttachmentType = useTaxonomyOptions(
    ETaxonomy.AttachmentType
  )?.find((item) => item.code === EAttachmentType.EMPLOYEE_CONSENT)?.value;

  const { mutate: onEnrollPackage, isLoading: enrollInProgress } =
    useEnrollPackage(false);
  const { mutate: onUpgradePackage, isLoading: upgradeEnrollInProgress } =
    useUpgradeEnrollment(false);

  const consentOptions = useTaxonomyOptions(ETaxonomy.ConsentType);
  const consentDeliveryOptions = useTaxonomyOptions(ETaxonomy.DeliveryType);

  const handleEnrollPackage = useCallback(
    (values) => {
      const mutationFn = isUpgradeEnroll ? onUpgradePackage : onEnrollPackage;
      const consentDocuments = values["scannedSignedConsentDocument"];
      mutationFn(
        {
          countryCode: Number(benefitPackage.countryCode),
          clientInternalNumberGOS: Number(
            benefitPackage.clientInternalNumberGos
          ),
          benefitPackageTag: Number(benefitPackage.benefitPackageTag),
          employeeDependentTag:
            Number(benefitPackage.employeeDependentTag) || 0,
          employeeEnrollmentTag: Number(benefitPackage.employeeEnrollmentTag),
          dateOfEnrollment:
            formatDateFieldForPayload(values["dateOfEnrollment"]) || "",
          payload: {
            attachments: consentDocuments
              ? consentDocuments?.map((document) => {
                  return {
                    ...document,
                    attachmentType: consentAttachmentType,
                  };
                })
              : [],
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.packageEnrolledSuccessfully.label"));
            setOpen(false);
          },
        }
      );
    },
    [benefitPackage, onEnrollPackage, isUpgradeEnroll]
  );

  const initialValues = useMemo(() => {
    if (!benefitPackage) return {};

    const initialData = {
      cancellationPossibility: benefitPackage?.cancellationPossibility,
      iAgreeTextConsent: false,
      dateOfEnrollment: new Date(),
      insurerConsentType: consentOptions?.find(
        (consentType) =>
          consentType.value === benefitPackage?.insurerConsentType
      ),
      insurerConsentText: benefitPackage?.insurerConsentText,
      insurerConsentUrl: benefitPackage?.insurerConsentUrl || "",
      insurerConsentDeliveryType: consentDeliveryOptions?.find(
        (consentType) =>
          consentType.value === benefitPackage?.insurerConsentDeliveryType
      ),
      insurerConsentDocumentPath: benefitPackage?.insurerConsentDocumentPath,
    };

    return initialData;
  }, [benefitPackage, consentDeliveryOptions, consentOptions]);

  if (!benefitPackage) {
    return <AppLogoSpinner />;
  }

  const isLoading = enrollInProgress || upgradeEnrollInProgress;

  const validationSchema = yup.object().shape({
    dateOfEnrollment: yup.mixed().required(),

    scannedSignedConsentDocument: yup
      .mixed()
      .when(
        ["insurerConsentType", "insurerConsentDeliveryType"],
        ([insurerConsentType, insurerConsentDeliveryType], schema) => {
          const insurerFormConsentType =
            insurerConsentType?.value === EInsurerConsentType.INSURER_FORM;

          const isInsurerFormConsentDeliveryTypeUploadScan =
            insurerFormConsentType &&
            insurerConsentDeliveryType?.code ===
              IConsentDeliveryType.UploadScan;
          if (
            insurerFormConsentType &&
            isInsurerFormConsentDeliveryTypeUploadScan &&
            !isUpgradeEnroll
          ) {
            return yup.array().min(1).required();
          }
          return yup.mixed().nullable();
        }
      ),
    iAgreeTextConsent: yup
      .mixed()
      .when(["insurerConsentType"], ([insurerConsentType], schema) => {
        const iAgreeTextConsentType =
          insurerConsentType?.value === EInsurerConsentType.I_AGREE_TEXT;
        if (iAgreeTextConsentType) {
          return yup.boolean().oneOf([true]).required();
        }
        return yup.boolean().nullable();
      }),
  });

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isLoading && <AppLogoSpinner />}
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values, actions) => {
            // handleSubmit(values);
            handleEnrollPackage(values);
            actions.setSubmitting(false);
          }}
        >
          {({ errors }) => {
            const hasErrors = Object.keys(errors).length > 0;
            return (
              <Form>
                <EnrollPackageFormItems
                  benefitPackage={benefitPackage}
                  isUpgradeEnroll={isUpgradeEnroll}
                />
                <Flex width="100%" $justify="flex-end" $gap={"10px"}>
                  <Button
                    onClick={() => {
                      setOpen(false);
                    }}
                    appearance="secondary"
                    icon={
                      <CancelIcon
                        style={{
                          fontSize: 16,
                        }}
                      />
                    }
                  >
                    {t("greco.cancel")}
                  </Button>
                  <DialogActions>
                    <PrimaryButton
                      disabled={hasErrors}
                      icon={
                        isUpgradeEnroll ? (
                          <RocketRegular />
                        ) : (
                          <GiftOpenRegular />
                        )
                      }
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {isUpgradeEnroll
                        ? t("hbh.upgrade.label")
                        : t("hbh.enroll.label")}
                    </PrimaryButton>
                  </DialogActions>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 600px;
    min-width: 600px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
