import { Text } from "@fluentui/react-components";
import {
  CalendarCancelRegular,
  CalendarCheckmarkRegular,
  CalendarClockRegular,
  DismissFilled,
  DocumentBulletListRegular,
  Gift24Regular,
  GiftOpen24Regular,
  MoneyRegular,
  Rocket24Regular,
  TextBulletListSquareWarningRegular,
  WindowBulletListAddRegular,
} from "@fluentui/react-icons";
// import ApprovalImg from "../../assets/approval.png";

import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
// import { LOCATION_STATES, ROUTES } from "library/constants";
import { DocumentsDialog } from "components/DocumentsDialog/DocumentsDialog";
import { isEqual, isWithinInterval } from "date-fns";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { EApprovalStatus, ETaxonomy, ICardBenefitPackage } from "library/types";
import { formatDateString, formatNumber, genUUID } from "library/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PlaceholderImg from "../../assets/download2.png";
import { CancelEnrolledPackage } from "./CancelEnrolledPackage";
import { EnrollPackageDialog } from "./EnrollPackageDialog/EnrollPackageDialog";
import {
  ActionWrap,
  BenefitImage,
  CanceledWrap,
  Desc,
  DocumentsWrap,
  ExpiredWrap,
  PackageName,
  PriceWrapInline,
  RejectInfoWrap,
  StyledBody1,
  StyledCard,
  StyledCardHeader,
  StyledCardPreview,
  StyledTag,
  StyledTagGroup,
  UpgradeableWrap,
  WaitingForApprovalWrap,
} from "./style";

type Props = {
  benefitPackage?: ICardBenefitPackage;

  previewMode?: boolean;
  selected?: boolean;
  isDependent?: boolean;
  eligible?: boolean;
  waitingForApproval?: boolean;
  waitingForCancel?: boolean;
  packageFormSameImplementationsInPendingStatus?: string;
  upgradeState?: {
    enrolledPackageName: string;
    upgradePackageName: string;
  };
};

export const BenefitCard = ({
  benefitPackage,
  previewMode,
  selected,
  upgradeState,
  isDependent,
  eligible,
  waitingForApproval,
  packageFormSameImplementationsInPendingStatus,
  waitingForCancel,
}: Props) => {
  const { t } = useTranslation();

  const [enrollOpen, setEnrollOpen] = useState(false);
  const [cancelEnrollOpen, setCancelEnrollOpen] = useState(false);
  const [showBenefitDocuments, setShowBenefitDocuments] = useState(false);

  const setEnrollOpenState = useCallback((state) => {
    setEnrollOpen(state);
  }, []);

  const setCancelEnrollOpenState = useCallback((state) => {
    setCancelEnrollOpen(state);
  }, []);

  const setBenefitDocumentOpenState = useCallback((state) => {
    setShowBenefitDocuments(state);
  }, []);

  const theme = useAppTheme();

  const packageIsExpired = !isWithinInterval(new Date(), {
    start: new Date(benefitPackage?.validFrom || ""),
    end: new Date(benefitPackage?.validTo || ""),
  });

  const currencyOptions = useTaxonomyOptions(ETaxonomy.Currency);

  const cancelEnrollmentDisabled = (() => {
    const formattedCancelPossibility =
      formatDateString(benefitPackage?.cancellationPossibility) || "";
    const formattedValidTo = formatDateString(benefitPackage?.validTo) || "";

    return (
      isEqual(formattedCancelPossibility, formattedValidTo) || packageIsExpired
    );
  })();

  const benefitPriceForEmployee = useMemo(() => {
    let paidByEmployee: number;
    const isPercentageValue = benefitPackage?.usingPercentagesForPaidBy;
    if (isPercentageValue) {
      paidByEmployee =
        ((benefitPackage?.price || 0) / 100) *
        (benefitPackage?.paidByEmployee || 0);
    } else {
      paidByEmployee = benefitPackage?.paidByEmployee || 0;
    }
    return formatNumber(paidByEmployee);
  }, [
    benefitPackage?.paidByEmployee,
    benefitPackage?.price,
    benefitPackage?.usingPercentagesForPaidBy,
  ]);

  const currency = currencyOptions?.find(
    (item) => item.value === benefitPackage?.currencyCode
  );

  const addNewLabel = t("hbh.enrollPackage.label");
  const packageFromSameImplementationInPendingStatusLabel = t(
    "hbh.packageFormSameImplementationsInPendingStatus.label",
    {
      packageName: packageFormSameImplementationsInPendingStatus,
    }
  );
  const upgradeLabel = t("hbh.upgradePackage.label", {
    enrolledPackageName: upgradeState?.enrolledPackageName,
    upgradePackageName: upgradeState?.upgradePackageName,
  });
  const deleteLabel = t("hbh.cancelPackage.label");
  const disabledCancelLabel = t("hbh.disabledCancelPackage.label");
  const benefitPriceLabel = t("hbh.benefitPrice.label");

  const hasRejectedStatus =
    benefitPackage?.approveEnrollStatusCode === EApprovalStatus.REJECTED ||
    benefitPackage?.cancelEnrollApprovalStatusCode === EApprovalStatus.REJECTED;

  const tagGroup = [
    ...(eligible
      ? [
          {
            label: "",
            value: "",
            icon: (
              <ButtonWithTooltip
                tooltipContent={`${t(
                  "hbh.eligibleFrom.label"
                )}: ${formatDateString(
                  benefitPackage?.dateOfEligibility
                )} - ${t("hbh.eligibleTo.label")}: ${formatDateString(
                  benefitPackage?.endDateOfEligibility
                )}`}
                icon={<WindowBulletListAddRegular />}
              >
                {`${t("hbh.eligible.label")}: ${formatDateString(
                  benefitPackage?.dateOfEligibility
                )} - ${formatDateString(benefitPackage?.endDateOfEligibility)}`}
              </ButtonWithTooltip>
            ),
          },
        ]
      : []),
    {
      label: "",
      value: "",
      icon: (
        <ButtonWithTooltip
          tooltipContent={`${t("hbh.validFrom.label")}: ${formatDateString(
            benefitPackage?.validFrom
          )} - ${t("hbh.validTo.label")}: ${formatDateString(
            benefitPackage?.validTo
          )}`}
          icon={<CalendarClockRegular />}
        >
          {`${t("hbh.valid.label")}: ${formatDateString(
            benefitPackage?.validFrom
          )} - ${formatDateString(benefitPackage?.validTo)}`}
        </ButtonWithTooltip>
      ),
    },
    ...(benefitPackage?.dateOfEnrollment
      ? [
          {
            label: "",
            value: "",
            icon: (
              <ButtonWithTooltip
                tooltipContent={`${t(
                  "hbh.enrolledOn.label"
                )}: ${formatDateString(benefitPackage?.dateOfEnrollment)}`}
                icon={<CalendarCheckmarkRegular />}
              >
                {`${t("hbh.enrolledOn.label")}: ${formatDateString(
                  benefitPackage?.dateOfEnrollment
                )}`}
              </ButtonWithTooltip>
            ),
          },
        ]
      : []),
    ...(benefitPackage?.cancellationPossibility &&
    !benefitPackage?.dateOfCancellation
      ? [
          {
            label: "",
            value: "",
            icon: (
              <ButtonWithTooltip
                tooltipContent={`${t(
                  "hbh.cancellationPossibility.label"
                )}: ${formatDateString(
                  benefitPackage?.cancellationPossibility
                )}`}
                icon={<CalendarCancelRegular />}
              >
                {`${t("hbh.cancellationPossibility.label")}: ${formatDateString(
                  benefitPackage?.cancellationPossibility
                )}`}
              </ButtonWithTooltip>
            ),
          },
        ]
      : []),
    ...(benefitPackage?.dateOfCancellation
      ? [
          {
            label: "",
            value: "",
            icon: (
              <ButtonWithTooltip
                style={{
                  color: theme.palette.redDark,
                }}
                tooltipContent={`${t(
                  "hbh.canceledOn.label"
                )}: ${formatDateString(benefitPackage?.dateOfCancellation)}`}
                icon={<CalendarCancelRegular />}
              >
                {`${t("hbh.canceledOn.label")}: ${formatDateString(
                  benefitPackage?.dateOfCancellation
                )}`}
              </ButtonWithTooltip>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <StyledCard
        $isSelected={selected}
        $canceled={!!benefitPackage?.dateOfCancellation}
        $expired={packageIsExpired}
      >
        <StyledCardPreview>
          {eligible && !waitingForApproval && !waitingForCancel ? (
            <ActionWrap $isAdd={!!eligible}>
              <ButtonWithTooltip
                disabled={!!packageFormSameImplementationsInPendingStatus}
                tooltipContent={(() => {
                  if (packageFormSameImplementationsInPendingStatus) {
                    return packageFromSameImplementationInPendingStatusLabel;
                  }
                  if (upgradeState) {
                    return upgradeLabel;
                  }

                  return addNewLabel;
                })()}
                handleClick={() => {
                  setEnrollOpenState(true);
                }}
                icon={
                  upgradeState ? <Rocket24Regular /> : <GiftOpen24Regular />
                }
              />
            </ActionWrap>
          ) : null}

          {!eligible && !benefitPackage?.dateOfCancellation ? (
            <ActionWrap $isAdd={!!eligible}>
              <ButtonWithTooltip
                disabled={cancelEnrollmentDisabled}
                tooltipContent={
                  cancelEnrollmentDisabled ? disabledCancelLabel : deleteLabel
                }
                handleClick={() => {
                  setCancelEnrollOpenState(true);
                }}
                icon={<DismissFilled />}
              />
            </ActionWrap>
          ) : null}
          {benefitPackage?.enrollmentDocuments?.length ? (
            <DocumentsWrap>
              <ButtonWithTooltip
                tooltipContent={t("hbh.viewBenefitDocuments.label")}
                handleClick={() => {
                  setBenefitDocumentOpenState(true);
                }}
                icon={<DocumentBulletListRegular />}
              />
            </DocumentsWrap>
          ) : null}
          {hasRejectedStatus ? (
            <RejectInfoWrap>
              <ButtonWithTooltip
                style={{
                  width: "35px",
                  height: "35px",
                }}
                tooltipContent={benefitPackage?.rejectionComment || ""}
                icon={
                  <TextBulletListSquareWarningRegular
                    fontSize={35}
                    style={{
                      color: theme.palette.redDark,
                    }}
                  />
                }
              />
            </RejectInfoWrap>
          ) : null}
          <BenefitImage
            src={benefitPackage?.backgroundPicturePath || PlaceholderImg}
            alt={benefitPackage?.name}
            loading="lazy"
          />
        </StyledCardPreview>

        <StyledCardHeader
          image={
            <Gift24Regular
              style={{
                color: theme.palette.yellowDark,
              }}
            />
          }
          header={
            <StyledBody1>
              <StyledTooltip
                content={benefitPackage?.name || ""}
                relationship="description"
              >
                <PackageName block truncate weight="bold">
                  {benefitPackage?.name}
                </PackageName>
              </StyledTooltip>
              <PriceWrapInline>
                <ButtonWithTooltip
                  style={{
                    minWidth: 0,
                    fontSize: "12px",
                    flexWrap: "wrap",
                  }}
                  tooltipContent={`${benefitPriceLabel}: ${benefitPriceForEmployee} ${
                    currency?.text ?? ""
                  }`}
                  icon={<MoneyRegular />}
                >
                  {`${benefitPriceForEmployee} ${currency?.text ?? ""}`}
                </ButtonWithTooltip>
              </PriceWrapInline>
            </StyledBody1>
          }
        />

        <StyledTooltip
          relationship="label"
          content={{
            children: benefitPackage?.description,
          }}
        >
          <Desc>{benefitPackage?.description}</Desc>
        </StyledTooltip>
        {tagGroup?.length ? (
          <StyledTagGroup aria-label="Simple tag group with Tag" role="list">
            {tagGroup.map((tag) => {
              return (
                <StyledTag
                  appearance="filled"
                  role="listitem"
                  key={genUUID()}
                  media={tag.icon}
                  shape="circular"
                >
                  {tag.label && <Text weight="semibold">{tag.label}:</Text>}
                  {tag.value && <Text>{tag.value}</Text>}
                </StyledTag>
              );
            })}
          </StyledTagGroup>
        ) : null}
        {benefitPackage?.dateOfCancellation && <CanceledWrap />}
        {packageIsExpired && <ExpiredWrap />}
        {(waitingForApproval || waitingForCancel) && (
          <WaitingForApprovalWrap>
            <Text
              block
              size={500}
              weight="bold"
              style={{
                transform: "rotate(-25deg)",
                backgroundColor: theme.palette.white,
                height: "fit-content",
                color: theme.palette.black,
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                gap: 5,
              }}
            >
              <>
                {waitingForApproval
                  ? t("hbh.waitingForApproval.label")
                  : t("hbh.waitingForCancellation.label")}
              </>
            </Text>
          </WaitingForApprovalWrap>
        )}
        {eligible && benefitPackage?.isUpgradeable && (
          <UpgradeableWrap>
            <ButtonWithTooltip
              tooltipContent={t("hbh.isUpgradeableExplanation.label")}
              icon={
                <Rocket24Regular
                  style={{
                    color: theme.palette.green,
                  }}
                />
              }
            />
          </UpgradeableWrap>
        )}
      </StyledCard>
      {enrollOpen && benefitPackage && (
        <EnrollPackageDialog
          isUpgradeEnroll={!!upgradeState}
          open={enrollOpen}
          setOpen={setEnrollOpenState}
          benefitPackage={benefitPackage}
        />
      )}

      {cancelEnrollOpen && benefitPackage && (
        <CancelEnrolledPackage
          open={cancelEnrollOpen}
          setOpen={setCancelEnrollOpenState}
          benefitPackage={benefitPackage}
        />
      )}
      {showBenefitDocuments && benefitPackage && (
        <DocumentsDialog
          title={t("hbh.benefitDocuments.label")}
          open={showBenefitDocuments}
          setOpen={setBenefitDocumentOpenState}
          documents={benefitPackage?.enrollmentDocuments || []}
        />
      )}
    </>
  );
};
