import useEmployee from "hooks/useEmployee";
import { ROUTES } from "library/constants";
import { Navigate, RouteProps } from "react-router-dom";

type Props = {
  children: JSX.Element;
} & RouteProps;

export const ConsentGuardedRoute = ({ children }: Props) => {
  const { employeeData } = useEmployee();

  if (!employeeData) return null;

  return employeeData?.isConsentGiven ? (
    <>{children}</>
  ) : (
    <Navigate to={ROUTES.PROFILE} />
  );
};
