import { DeleteIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import SelectField from "components/controls/SelectField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex, RemoveButton } from "components/styled";
import { formatDate } from "date-fns";
import { Form, Formik } from "formik";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
// import {
//   EmployeeRowItem,
//   ETaxonomy,
//   IEmployeeDependent,
//   IEmployeeView,
// } from "library/types";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { Button, Persona } from "@fluentui/react-components";
import {
  ArrowStepBackRegular,
  EditArrowBackRegular,
  EditRegular,
} from "@fluentui/react-icons";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { CollapseWrapper } from "components/CollapseWrapper/CollapseWrapper";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { devices } from "library/constants";
import { ETaxonomy } from "library/types";
import { useTranslation } from "react-i18next";
import { useCreateDependent } from "state/useCreateDependent";
import { useDeleteDependent } from "state/useDeleteDependent";
import { useUpdateDependent } from "state/useUpdateDependent";
import { selectedClientAtom } from "store";
import styled from "styled-components";
import * as yup from "yup";
import { BasicFields } from "../BasicFields";
import { MainFields } from "../MainFields";

type Props = {
  removeNewDependent?: () => void;
  dependentData?: any;
  addNew?: boolean;
  employeeData?: any;
};
export function DependentItem({
  removeNewDependent,
  dependentData,
  employeeData,
  addNew,
}: Props) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(addNew ? true : false);
  const dependentTypeOptions = useTaxonomyOptions(ETaxonomy.DependentType);
  const selectedCompany = useAtomValue(selectedClientAtom);

  const genderOptions = useTaxonomyOptions(ETaxonomy.Gender);
  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  const { mutate: onUpdateDependent, isLoading: updateInProgress } =
    useUpdateDependent(false);

  const { mutate: onCreateDependent, isLoading: createInProgress } =
    useCreateDependent(false);

  const { mutate: onDeleteDependent, isLoading: deleteInProgress } =
    useDeleteDependent(false);

  const [deleteDependentConfirmationOpen, setDeleteDependentConfirmationOpen] =
    useState(false);

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteDependentConfirmationOpen((prev) => !prev);
  }, []);

  const initDependentType = dependentTypeOptions.find(
    (option) => option.value === dependentData?.dependentType
  );

  const initialValues = useMemo(() => {
    if (!dependentData) return {};
    return {
      ...dependentData,
      dependentType: initDependentType,
      dateOfBirth: new Date(dependentData?.dateOfBirth || ""),

      //basic
      genderCodeId: genderOptions.find(
        (genderCode) => genderCode.value === dependentData?.genderCodeId
      ),
      citizenshipCodeId: countryOptions.find(
        (item) => item.value === dependentData?.citizenshipCodeId
      ),
      dependentCoverPhoto: dependentData?.backgroundPicturePath
        ? {
            name: dependentData?.backgroundPictureName,
            path: dependentData?.backgroundPicturePath,
            base64String: dependentData?.backgroundPictureContent,
            type: dependentData?.backgroundPictureMimeType,
          }
        : null,
      employeeDependentTag: dependentData?.employeeDependentTag,
    };
  }, [dependentData]);

  const validationSchema = yup.object().shape({
    dependentType: yup.mixed().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    dateOfBirth: yup.mixed().required(),
    // emailAddress: yup.string().required(),
  });

  const handleDeleteDependent = () => {
    if (!dependentData) return;
    onDeleteDependent(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        employeeDependentTag: Number(dependentData?.employeeDependentTag),
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.dependentDeletedSuccessfully.label"));
          setEditMode(false);
        },
      }
    );
  };

  const handleSubmit = (values) => {
    const payload = {
      ...dependentData,
      ...values,
      employeeLoginEmailAddress: employeeData?.employeeLoginEmailAddress,
      dependentType: formatSelectFieldForPayload({
        values,
        field: "dependentType",
      }),
      dateOfBirth: formatDate(values.dateOfBirth, "yyyy-MM-dd"),
      genderCodeId: formatSelectFieldForPayload({
        values,
        field: "genderCodeId",
      }),
      citizenshipCodeId: formatSelectFieldForPayload({
        values,
        field: "citizenshipCodeId",
      }),
      ...(values.dateOfBirth
        ? { dateOfBirth: formatDateFieldForPayload(values.dateOfBirth) }
        : {}),
      dateOfExit: formatDateFieldForPayload(values.dateOfExit),

      backgroundPictureName: values?.dependentCoverPhoto?.name,
      backgroundPictureContent: values?.dependentCoverPhoto?.base64String,
      backgroundPictureMimeType: values?.dependentCoverPhoto?.type,
      ...(!values?.dependentCoverPhoto?.base64String && {
        backgroundPicturePath: values?.dependentCoverPhoto?.path,
      }),
      rowVersion: employeeData?.rowVersion,
    };

    if (dependentData) {
      onUpdateDependent(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),

          employeeDependentTag: Number(dependentData?.employeeDependentTag),
          payload: payload,
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.dependentUpdatedSuccessfully.label"));
            setEditMode(false);
          },
        }
      );
    } else {
      onCreateDependent(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          payload,
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.dependentAddedSuccessfully.label"));
            removeNewDependent && removeNewDependent();
            setEditMode(false);
          },
        }
      );
    }
  };

  const isLoading = createInProgress || updateInProgress || deleteInProgress;

  return (
    <>
      {addNew ? (
        <Button
          appearance="transparent"
          icon={<ArrowStepBackRegular />}
          style={{
            width: "fit-content",
          }}
          onClick={() => {
            setEditMode(false);
            removeNewDependent && removeNewDependent();
          }}
        >
          {t("greco.cancel")}
        </Button>
      ) : (
        <DependentNameWrap>
          <Persona
            name={`${dependentData?.firstName} ${dependentData?.lastName}`}
            secondaryText={initDependentType?.text}
            // presence={{ status: "available" }}
            avatar={{
              image: {
                src: dependentData?.backgroundPicturePath || "",
              },
            }}
          />
          <Button
            appearance="transparent"
            onClick={() => {
              setEditMode((prev) => !prev);
            }}
            icon={editMode ? <EditArrowBackRegular /> : <EditRegular />}
          />
        </DependentNameWrap>
      )}
      <CollapseWrapper visible={!!editMode}>
        <Container>
          <Formik
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              dirty,

              setFieldValue,
              /* and other goodies */
            }) => {
              return (
                <>
                  {isLoading && <AppLogoSpinner />}
                  <Form>
                    <SelectField
                      required
                      error={errors["dependentType"] as string}
                      setFieldValue={setFieldValue}
                      value={values["dependentType"]}
                      label="Dependent"
                      options={dependentTypeOptions}
                      name={"dependentType"}
                      showEmptyOption
                      appearance="filled-darker"
                    />
                    <MainFields appearance="filled-darker" isDependent={true} />
                    <BasicFields
                      appearance="filled-darker"
                      isDependent={true}
                    />
                    <Flex wrap="nowrap" $justify="space-between">
                      {!addNew && (
                        <RemoveBtnWrap>
                          <RemoveButton
                            disabled={isLoading}
                            icon={<DeleteIcon />}
                            onClick={() => {
                              setDeleteDependentConfirmationOpen(true);
                            }}
                          >
                            {addNew ? t("greco.cancel") : t("greco.delete")}
                          </RemoveButton>
                        </RemoveBtnWrap>
                      )}
                      <Flex
                        style={{
                          marginLeft: "auto",
                        }}
                        wrap="nowrap"
                      >
                        <Button
                          icon={<ArrowStepBackRegular />}
                          onClick={() => {
                            setEditMode(false);
                            removeNewDependent && removeNewDependent();
                          }}
                        >
                          {t("greco.cancel")}
                        </Button>
                        <PrimaryButton
                          disabled={!dirty || isLoading}
                          icon={<SaveIcon />}
                          style={{
                            paddingLeft: "25px",
                            paddingRight: "25px",
                          }}
                        >
                          {t("greco.save")}
                        </PrimaryButton>
                      </Flex>
                    </Flex>
                  </Form>
                </>
              );
            }}
          </Formik>
        </Container>
      </CollapseWrapper>

      {deleteDependentConfirmationOpen && dependentData && (
        <ConfirmationDialog
          isOpen={deleteDependentConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDeleteDependent}
          description={t("greco.dialog.deleteTitle", {
            object: `${dependentData?.firstName} ${dependentData?.lastName}`,
          })}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deleteDependent.label")} ${
            dependentData?.firstName
          } ${dependentData?.lastName}`}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  box-sizing: border-box;
  padding: 10px;
`;

const RemoveBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    width: 100%;
  }
`;

const DependentNameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 5px 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
  padding-bottom: 5px;
  @media only screen and ${devices.md} {
    width: 500px;
  }
`;
