import { ProfileContentWrap } from "components/styled";
import useEmployee from "hooks/useEmployee";
import { IAttachment, IFormattedFile } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateEmployeeAttachment } from "state/useCreateEmployeeAttachment";
import { useDeleteEmployeeAttachment } from "state/useDeleteEmployeeAttachment";
import { useUpdateEmployeeAttachment } from "state/useUpdateEmployeeAttachment";
import { ManageEmployeeDocuments } from "./ManageEmployeeDocuments";

export function EmployeeDocuments() {
  const { employeeData } = useEmployee();

  const { t } = useTranslation();
  // const selectedCompany = useAtomValue(selectedCompanyState);

  const [open, setOpen] = useState(false);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const employeeAttachmentsNumber = employeeData?.attachments?.length;

  const { mutate: onCreateAttachment, isLoading: createAttachmentInProgress } =
    useCreateEmployeeAttachment(false);

  const { mutate: onUpdateAttachment, isLoading: updateInProgress } =
    useUpdateEmployeeAttachment(false);

  const { mutate: onDeleteAttachment, isLoading: deleteInProgress } =
    useDeleteEmployeeAttachment(false);

  const handleUpdateAttachment = useCallback(
    ({
      attachment,
      newValues,
    }: {
      attachment: IAttachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => {
      onUpdateAttachment(
        {
          attachmentName: attachment.attachmentName || "",
          clientInternalNumberGOS: employeeData?.clientInternalNumberGos || 0,
          countryCode: employeeData?.countryCode || 0,
          payload: {
            ...attachment,
            attachmentName: newValues?.attachmentName,
            attachmentSummary: newValues?.attachmentSummary,
            attachmentAbstract: newValues?.attachmentAbstract,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentUpdatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const titleLabel = employeeData?.attachments?.length
    ? t("hbh.manageDocuments.label")
    : t("hbh.addDocuments.label");

  const handleCreateAttachment = useCallback(
    (newAttach: IFormattedFile | null) => {
      onCreateAttachment(
        {
          clientInternalNumberGOS: employeeData?.clientInternalNumberGos || 0,
          countryCode: employeeData?.countryCode || 0,
          payload: {
            attachmentName: newAttach?.name || "",
            attachmentContent: newAttach?.base64String || "",
            attachmentMimeType: newAttach?.type || "",
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.documentCreatedSuccessfully.label"));
          },
        }
      );
    },
    []
  );

  const handleDeleteAttachment = useCallback((attach: IAttachment) => {
    onDeleteAttachment(
      {
        attachmentName: attach.attachmentName || "",
        clientInternalNumberGOS: employeeData?.clientInternalNumberGos || 0,
        countryCode: employeeData?.countryCode || 0,
        rowVersion: attach.rowVersion || "",
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.documentDeletedSuccessfully.label"));
        },
      }
    );
  }, []);

  if (!employeeData) return null;
  return (
    <ProfileContentWrap>
      <ManageEmployeeDocuments
        attachmentActionsState={{
          createAttachmentInProgress,
          onCreateAttachment: handleCreateAttachment,
          updateInProgress,
          onUpdateAttachment: handleUpdateAttachment,
          deleteInProgress,
          onDeleteAttachment: handleDeleteAttachment,
        }}
        item={employeeData}
      />
    </ProfileContentWrap>
  );
}
