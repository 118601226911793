import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { ICompanyPackageView } from "library/types";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";
import api from "service/api";

type Props = {
  countryCode: number;
  clientInternalNumberGos: number;
  enabled?: boolean;
};

const getCompanyBenefitPackageViews = async ({
  countryCode,
  clientInternalNumberGos,
}: Props) => {
  const response = await api.get(
    `/company/benefitpackage/view/${countryCode}/${clientInternalNumberGos}`
  );

  return response.data;
};

export function useGetCompanyBenefitPackageViews({
  enabled,
  countryCode,
  clientInternalNumberGos,
}: Props): UseQueryResult<ICompanyPackageView[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<ICompanyPackageView[], Error>(
    [
      QUERY_KEYS.companyBenefitPackageViews,
      countryCode,
      clientInternalNumberGos,
    ],
    () => {
      return getCompanyBenefitPackageViews({
        countryCode,
        clientInternalNumberGos,
      });
    },
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}

export const useInvalidateCompanyBenefitPackageViews = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: QUERY_KEYS.companyBenefitPackageViews,
    });
};
