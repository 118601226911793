import { atom } from "jotai";
import { darkStyledTheme, styledTheme } from "theme/mainTheme";
import { STORAGE_KEYS } from "../library/shared";
import {
  IChatbotMessageHistory,
  IClient,
  INotification,
} from "../library/types";
import { loadFromLocalStorage, saveToLocalStorage } from "../library/utils";

const darkModeLocalStorageAtom = atom(
  // Initialize with value from localStorage or a fallback value
  loadFromLocalStorage(STORAGE_KEYS.darkMode, "") // Can be any default value or object
);

export const darkModeAtom = atom(
  (get) => get(darkModeLocalStorageAtom), // Read atom value
  (get, set, newValue) => {
    set(darkModeLocalStorageAtom, newValue); // Update atom
    saveToLocalStorage(STORAGE_KEYS.darkMode, newValue); // Save new value to localStorage
  }
);

export const panelIsOpenState = atom(false);

export const chatPanelOpenAtom = atom(false);
export const chatIdAtom = atom<any>(null);

const selectedFullThemeLocalStorageAtom = atom(
  // Initialize with value from localStorage or a fallback value
  loadFromLocalStorage(STORAGE_KEYS.selectedFullTheme, {
    light: styledTheme,
    dark: darkStyledTheme,
  }) // Can be any default value or object
);

export const notificationMessagesAtom = atom<INotification[] | []>([]);

export const selectedClientAtom = atom<IClient | null | undefined>();

export const selectedFullThemeAtom = atom(
  (get) => get(selectedFullThemeLocalStorageAtom), // Read atom value
  (get, set, newValue) => {
    set(selectedFullThemeLocalStorageAtom, newValue); // Update atom
    saveToLocalStorage(STORAGE_KEYS.selectedFullTheme, newValue); // Save new value to localStorage
  }
);

export const chatHistoryAtom = atom<IChatbotMessageHistory[]>([]);
