import DatePickerField from "components/controls/DatePickerField";
import { useFormikContext } from "formik";
import { ICardBenefitPackage } from "library/types";
import { formatDateFieldForPayload } from "library/utils";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getEnrollmentCancellationPossibilityDate } from "state/useGetEnrollmentCancellationPossibilityDate";
import { ConsentDependencyFields } from "./ConsentDependencyFields/ConsentDependencyFields";

type Props = {
  benefitPackage: ICardBenefitPackage;
  isUpgradeEnroll?: boolean;
};
export function EnrollPackageFormItems({
  benefitPackage,
  isUpgradeEnroll,
}: Props) {
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const getCancellationPossibility = async (benefitPackage) => {
    const cancellationPossibility =
      await getEnrollmentCancellationPossibilityDate({
        countryCode: Number(benefitPackage.countryCode),
        clientInternalNumberGOS: Number(benefitPackage.clientInternalNumberGos),
        benefitPackageTag: Number(benefitPackage.benefitPackageTag),
        employeeDependentTag: benefitPackage.employeeDependentTag
          ? Number(benefitPackage.employeeDependentTag)
          : 0,
        employeeEnrollmentTag: Number(benefitPackage.employeeEnrollmentTag),
        dateOfEnrollment:
          formatDateFieldForPayload(values["dateOfEnrollment"]) || "",
      });
    setFieldValue("cancellationPossibility", new Date(cancellationPossibility));
  };

  useEffect(() => {
    if (values["dateOfEnrollment"] && !benefitPackage.cancellationPossibility) {
      getCancellationPossibility(benefitPackage);
    }
  }, [values["dateOfEnrollment"], benefitPackage?.cancellationPossibility]);

  return (
    <>
      <DatePickerField
        required
        minDate={new Date()}
        name="dateOfEnrollment"
        value={values["dateOfEnrollment"] as Date}
        setFieldValue={setFieldValue}
        label={t("hbh.dateOfEnrollment.label")}
        error={""}
      />
      <DatePickerField
        disabled
        name="cancellationPossibility"
        value={values["cancellationPossibility"] as Date}
        setFieldValue={() => {
          return;
        }}
        label={t("hbh.cancellationPossibility.label")}
        error={""}
      />
      {!isUpgradeEnroll && (
        <ConsentDependencyFields isUpgradeEnroll={isUpgradeEnroll} />
      )}
    </>
  );
}
