import { Text } from "@fluentui/react-components";
import { BoxSearchRegular } from "@fluentui/react-icons";
import { BenefitCard } from "components/BenefitCard/BenefitCard";
import { CardListWrap } from "components/styled";
import { isAfter, isBefore, isWithinInterval, startOfDay } from "date-fns";
import { useAtomValue } from "jotai";
import { EApprovalStatus, ICardBenefitPackage } from "library/types";
import { useTranslation } from "react-i18next";
import { selectedPackageUserAtom } from "store/UIBenefitsPage";
import styled from "styled-components";

type Props = {
  eligiblePackages: ICardBenefitPackage[];
  enrolledPackages: ICardBenefitPackage[];
};
export function EligiblePackages({
  eligiblePackages,
  enrolledPackages,
}: Props) {
  const { t } = useTranslation();
  const selectedPackageUser = useAtomValue(selectedPackageUserAtom);

  const enrolledPackagesImplementations = enrolledPackages.reduce(
    (acc, item) => {
      const implementationKey = item.benefitImplementationTag || "";
      const employeeKey = item.isDependent
        ? item.employeeDependentTag
        : item.employeeLoginEmailAddress;
      const dateOfCancellation = item?.dateOfCancellation || "";

      if (dateOfCancellation) {
        // if (isBefore(new Date(dateOfCancellation), new Date())) {
        //   return acc;
        // }
        return acc;
      }

      if (item.isDependent) {
        acc.dependent[employeeKey || ""] = {
          ...acc.dependent[employeeKey || ""],
          [implementationKey]: item,
        };
      } else {
        acc.employee[employeeKey || ""] = {
          ...acc.employee[employeeKey || ""],
          [implementationKey]: item,
        };
      }

      return acc;
    },
    { employee: {}, dependent: {} } // Initialize with employee and dependent objects
  );

  const packagesImplementationsOfPendingEnrollments = [
    ...eligiblePackages,
    ...enrolledPackages,
  ].reduce(
    (acc, item) => {
      const implementationKey = item.benefitImplementationTag || "";
      const employeeKey = item.isDependent
        ? item.employeeDependentTag
        : item.employeeLoginEmailAddress;
      const dateOfCancellation = item?.dateOfCancellation || "";

      if (dateOfCancellation) {
        if (isBefore(new Date(dateOfCancellation), new Date())) {
          return acc;
        }
      }
      const isInPendingStatus =
        item.approveEnrollStatusCode === EApprovalStatus.PENDING ||
        item.cancelEnrollApprovalStatusCode === EApprovalStatus.PENDING;

      if (!isInPendingStatus) return acc;

      if (item.isDependent) {
        acc.dependent[employeeKey || ""] = {
          ...acc.dependent[employeeKey || ""],
          [implementationKey]: item,
        };
      } else {
        acc.employee[employeeKey || ""] = {
          ...acc.employee[employeeKey || ""],
          [implementationKey]: item,
        };
      }

      return acc;
    },
    { employee: {}, dependent: {} } // Initialize with employee and dependent objects
  );

  const filteredData = eligiblePackages?.filter((item) => {
    const packageExpired = !isWithinInterval(new Date(), {
      start: new Date(item?.validFrom || ""),
      end: new Date(item?.validTo || ""),
    });

    const today = startOfDay(new Date());
    const eligibilityExpired = isBefore(item.endDateOfEligibility || "", today);

    if (packageExpired || eligibilityExpired) return false;

    if (item.isDependent) {
      const enrollFromSameImplementation =
        enrolledPackagesImplementations?.dependent?.[
          item?.employeeDependentTag || ""
        ]?.[item.benefitImplementationTag || ""];

      if (enrollFromSameImplementation && !item.isUpgradeable) return false; // If the dependent is already enrolled in the same implementation, and the benefit is not upgradeable, return false

      return item.employeeDependentTag === selectedPackageUser?.value;
    }

    const enrollFromSameImplementation =
      enrolledPackagesImplementations?.employee?.[
        item?.employeeLoginEmailAddress || ""
      ]?.[item.benefitImplementationTag || ""];
    if (enrollFromSameImplementation && !item.isUpgradeable) return false; // If the employee is already enrolled in the same implementation, and the benefit is not upgradeable, return false

    return item.employeeLoginEmailAddress === selectedPackageUser?.value;
  });

  return (
    <>
      {filteredData?.length ? (
        <CardListWrap>
          {filteredData.map((benefit) => {
            const upgradeData = (() => {
              const packageUser = benefit.isDependent
                ? "dependent"
                : "employee";
              const implementationKey = benefit.benefitImplementationTag || "";
              const employeeKey = benefit.isDependent
                ? benefit.employeeDependentTag
                : benefit.employeeLoginEmailAddress;

              const enrolledData =
                enrolledPackagesImplementations?.[packageUser]?.[
                  employeeKey || ""
                ]?.[implementationKey];

              return {
                hasUpgrade: !!enrolledData && benefit.isUpgradeable,
                enrolledPackageName: enrolledData?.name,
              };
            })();

            const packageFromSameImplementationInPendingStatusConfig = (() => {
              const packageUser = benefit.isDependent
                ? "dependent"
                : "employee";
              const implementationKey = benefit.benefitImplementationTag || "";
              const employeeKey = benefit.isDependent
                ? benefit.employeeDependentTag
                : benefit.employeeLoginEmailAddress;

              const packageInPendingStatus =
                packagesImplementationsOfPendingEnrollments?.[packageUser]?.[
                  employeeKey || ""
                ]?.[implementationKey];

              return {
                hasPendingStatus: !!packageInPendingStatus,
                packageInPendingStatus: packageInPendingStatus?.name,
              };
            })();

            const hasUpgradeAction = upgradeData?.hasUpgrade;
            const hasPackageFromSameImplementationInPendingStatus =
              packageFromSameImplementationInPendingStatusConfig?.hasPendingStatus;
            const enrolledPackageName = upgradeData?.enrolledPackageName || "";

            const requiresEnrollmentApproval =
              benefit?.requiresEnrollmentApproval;
            // const requiresCancellationApproval =
            //   benefit?.requiresCancellationApproval;

            const hasPendingStatus =
              benefit?.approveEnrollStatusCode === EApprovalStatus.PENDING;

            const waitingForApproval =
              requiresEnrollmentApproval &&
              !!benefit?.dateOfEnrollment &&
              hasPendingStatus;

            return (
              <BenefitCard
                waitingForApproval={waitingForApproval}
                {...(hasUpgradeAction
                  ? {
                      upgradeState: {
                        enrolledPackageName,
                        upgradePackageName: benefit?.name || "",
                      },
                    }
                  : {})}
                {...(hasPackageFromSameImplementationInPendingStatus
                  ? {
                      packageFormSameImplementationsInPendingStatus:
                        packageFromSameImplementationInPendingStatusConfig.packageInPendingStatus,
                    }
                  : {})}
                eligible
                key={benefit.benefitPackageTag}
                benefitPackage={benefit}
              />
            );
          })}
        </CardListWrap>
      ) : (
        <NoEligibleBenefitsText>
          <div>
            <BoxSearchRegular fontSize={25} />
          </div>
          <Text weight="semibold" block>
            {" "}
            {t("hbh.noEligibleBenefits.label")}
          </Text>
        </NoEligibleBenefitsText>
      )}
    </>
  );
}

const NoEligibleBenefitsText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  max-width: 600px;
`;
