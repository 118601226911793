import { ToggleButton } from "@fluentui/react-components";
import {
  DocumentSettingsRegular,
  InfoRegular,
  PeopleRegular,
} from "@fluentui/react-icons";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { useAppTheme } from "hooks/useAppTheme";
import useEmployee from "hooks/useEmployee";
import { useGetMsalUserEmail } from "hooks/useGetMsalUserEmail";
import { useAtom, useAtomValue } from "jotai";
import { devices } from "library/constants";
import { EProfileTab } from "library/types";
import { formatFile } from "library/utils";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUpdateEmployee } from "state/useUpdateEmployee";
import { selectedClientAtom } from "store";
import { selectedProfileTabAtom } from "store/UIProfilePage";
import styled from "styled-components";

export function ProfileHeader() {
  const { t } = useTranslation();
  const selectedClient = useAtomValue(selectedClientAtom);
  const { employeeData } = useEmployee();
  const loginEmail = useGetMsalUserEmail();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedTab, setSelectedTab] = useAtom(selectedProfileTabAtom);

  const onSelectTab = useCallback((tab: EProfileTab) => {
    setSelectedTab(tab);
  }, []);

  const { mutate: onUpdateEmployee, isLoading: updateEmployeeInProgress } =
    useUpdateEmployee(false);

  const handleUpdatePhoto = async (event) => {
    const file = event.target.files[0];
    const formattedFile = await formatFile(file);
    onUpdateEmployee(
      {
        countryCode: Number(selectedClient?.countryCode),
        clientInternalNumberGOS: Number(
          selectedClient?.clientInternalNumberGos
        ),
        payload: {
          ...employeeData,
          backgroundPictureContent: formattedFile?.base64String || "",
          backgroundPictureName: formattedFile?.name || "",
          backgroundPictureMimeType: formattedFile?.type || "",
          backgroundPicturePath: formattedFile?.path || "",
        },
      },
      {
        onSuccess() {
          toast.success(t("hbh.employeeUpdatedSuccessfully.label"));
        },
      }
    );
  };

  const employeeAttachmentsNumber = employeeData?.attachments?.length;

  return (
    <Container $bgImg={selectedClient?.backgroundPicturePath}>
      {updateEmployeeInProgress && <AppLogoSpinner />}
      <ImageWrap onClick={() => fileInputRef.current?.click()}>
        {employeeData?.backgroundPicturePath ? (
          <img src={employeeData?.backgroundPicturePath} alt="user-profile" />
        ) : (
          `${employeeData?.firstName?.charAt(
            0
          )}${employeeData?.lastName?.charAt(0)}`
        )}
        <input
          ref={fileInputRef}
          onChange={handleUpdatePhoto}
          type="file"
          accept="*"
          hidden
        />
      </ImageWrap>
      <UserWrap>{`${employeeData?.firstName} ${employeeData?.lastName} (${loginEmail})`}</UserWrap>
      <ButtonsWrap>
        <ToggleButton
          checked={selectedTab === EProfileTab.info}
          appearance="subtle"
          onClick={() => {
            onSelectTab(EProfileTab.info);
          }}
          icon={<InfoRegular />}
        >
          {t("hbh.info.label")}
        </ToggleButton>
        <ToggleButton
          checked={selectedTab === EProfileTab.documents}
          appearance="subtle"
          icon={<DocumentSettingsRegular />}
          onClick={() => {
            onSelectTab(EProfileTab.documents);
          }}
        >
          {t("hbh.documents.label")}
          {/* {employeeAttachmentsNumber
            ? ` (${employeeAttachmentsNumber} ${t("hbh.items.label")})`
            : ` (${t("hbh.noData.label")}) `} */}
        </ToggleButton>
        <ToggleButton
          checked={selectedTab === EProfileTab.dependents}
          appearance="subtle"
          icon={<PeopleRegular />}
          onClick={() => {
            onSelectTab(EProfileTab.dependents);
          }}
        >
          {t("hbh.dependents.label")}
        </ToggleButton>
      </ButtonsWrap>
    </Container>
  );
}

const Container = styled.div<{ $bgImg?: string }>`
  margin: 50px auto 30px;
  box-sizing: border-box;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: flex-end;
  padding: 5px 10px;
  position: relative;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.semanticColors.cardStandoutBackground};
  min-height: 120px;
  box-shadow: ${({ theme }) => theme.effects.elevation4};

  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.themeLight},
    ${({ theme }) => theme.palette.white}
  );

  @media only screen and ${devices.md} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 100px auto 30px;
    max-width: 1800px;
    min-height: 100px;

    margin-top: 100px;
    padding: 0 10px;
  }
`;
const OpacityBg = styled.div<{ $bgImg?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: url("${({ $bgImg }) => $bgImg}") repeat-x;
  background-size: contain;
  z-index: 4;

  opacity: 0.1;
`;
const LinearBg = styled.div<{ $bgImg?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.white},
    ${({ theme }) => theme.palette.themeLight}
  );
  opacity: 0.1;
`;

const UserWrap = styled.div`
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
  @media only screen and ${devices.md} {
    font-size: 18px;
    margin-left: 200px;
  }
`;

const ImageWrap = styled.div`
  display: flex;
  position: absolute;
  z-index: 5;
  left: 50%;
  transform: translate(-50%, -65px);
  border: 2px solid ${({ theme }) => theme.palette.white};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.neutralLighterAlt};
  font-size: 20px;
  font-weight: 600;
  width: 90px;
  height: 90px;
  cursor: pointer;

  img {
    width: 90px;
    height: 90px;
    border: 2px solid ${({ theme }) => theme.palette.white};
    border-radius: 50%;
  }

  @media only screen and ${devices.md} {
    width: 130px;
    height: 130px;
    left: 100px;
    transform: translate(-50%, -50px);
    img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
    }
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  gap: 5px;
  height: 30px;
  button {
    min-width: auto;
    padding: 0 5px;
  }
  @media only screen and ${devices.md} {
    padding-right: 40px;
  }
`;
