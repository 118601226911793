import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { IChatbotMessage } from "library/types";
import { useMutation } from "react-query";
import api from "service/api";

type Params = {
  payload: IChatbotMessage;
};

const sendMsgToChatbot = async ({ payload }: Params) => {
  const response = await api.post(`/ChatBot`, {
    ...payload,
  });

  return response.data as string;
};

export function useSendMsgToChatbot() {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(sendMsgToChatbot, {
    mutationKey: MUTATION_KEYS.sendMessageToChatbot,
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
