import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Button,
} from "@fluentui/react-components";
import {
  ArrowStepBackRegular,
  DocumentEditRegular,
  DocumentRegular,
} from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { Flex } from "components/styled";
import { UploadFile } from "components/UploadFile/UploadFile";
import { useAppTheme } from "hooks/useAppTheme";
import { fileExtensionIcons } from "library/shared";
import { IAttachment, IEmployee, IFormattedFile } from "library/types";
import { getExtensionFromUrl, openLinkInNewTab } from "library/utils";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AttachmentItem } from "./AttachmentItem";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";

type Props = {
  item: IEmployee;

  attachmentActionsState: {
    createAttachmentInProgress: boolean;
    deleteInProgress: boolean;
    onCreateAttachment: (
      attach: IFormattedFile | null,
      isHiddenForEmployee?: boolean
    ) => void;
    onDeleteAttachment: (attach: IAttachment) => void;
    onUpdateAttachment: ({
      attachment,
      newValues,
    }: {
      attachment: IAttachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => void;
    updateInProgress: boolean;
  };
};

export function ManageEmployeeDocuments({
  item,
  attachmentActionsState,
}: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const [openAttachForms, setOpenAttachForms] = React.useState([""]);
  const handleToggleAttachForm: AccordionToggleEventHandler<string> = (
    event,
    data
  ) => {
    setOpenAttachForms(data.openItems);
  };

  const [attachmentList, setAttachmentList] = React.useState<IAttachment[]>([]);
  const [newAttach, setNewAttach] = useState<IFormattedFile | null>(null);
  const [isAttachHiddenForEmployee, setIsAttachHiddenForEmployee] =
    useState(true);

  useEffect(() => {
    setAttachmentList(item.attachments || []);
  }, [item?.attachments]);

  const {
    deleteInProgress,
    createAttachmentInProgress,
    onCreateAttachment,
    updateInProgress,
    onDeleteAttachment,
    onUpdateAttachment,
  } = attachmentActionsState;

  const subjectName = (() => {
    return `${item?.firstName} ${item.lastName}`;
  })();

  return (
    <>
      <Container>
        {deleteInProgress || createAttachmentInProgress ? (
          <AppLogoSpinner />
        ) : null}
        <AttachmentList>
          <AddNewAttachment>
            <UploadFile
              defaultValue={newAttach}
              setFieldValue={(_, file) => {
                setNewAttach(file);
              }}
              fieldName=""
              label=""
            />
            <Flex wrap="nowrap">
              <Button
                style={{
                  padding: "5px 15px",
                }}
                disabled={!newAttach}
                icon={
                  <SaveIcon
                    style={{
                      color: theme.palette.themePrimary,
                      opacity: newAttach ? "1" : "0.5",
                    }}
                  />
                }
                // disabled={updateInProgress}
                appearance="outline"
                size="small"
                onClick={async (e) => {
                  e.stopPropagation();
                  await onCreateAttachment(
                    newAttach,
                    isAttachHiddenForEmployee
                  );
                  setNewAttach(null);
                  setIsAttachHiddenForEmployee(true);
                }}
              />
            </Flex>
          </AddNewAttachment>
          <Accordion
            openItems={openAttachForms}
            onToggle={handleToggleAttachForm}
            multiple
            collapsible
          >
            {attachmentList?.map((attachment, index) => {
              const docType = getExtensionFromUrl(attachment.attachmentPath);

              return (
                <AccordionItem key={index} value={attachment?.attachmentName}>
                  <AccordionHeader
                    size="large"
                    expandIconPosition="end"
                    expandIcon={
                      <Button
                        size="small"
                        appearance="subtle"
                        icon={
                          openAttachForms.includes(
                            attachment?.attachmentName || ""
                          ) ? (
                            <ArrowStepBackRegular />
                          ) : (
                            <DocumentEditRegular />
                          )
                        }
                      />
                    }
                  >
                    <Flex $justify="space-between" width="100%">
                      <Button
                        style={{
                          color: theme.palette.themePrimary,
                          textDecoration: "underline",
                        }}
                        href={attachment?.attachmentPath || ""}
                        onClick={(e) => {
                          e.stopPropagation();
                          openLinkInNewTab(attachment?.attachmentPath || "");
                        }}
                        appearance="transparent"
                        icon={
                          fileExtensionIcons[docType] || <DocumentRegular />
                        }
                      >
                        {attachment.attachmentName}
                      </Button>
                    </Flex>
                  </AccordionHeader>
                  <AccordionPanel>
                    <AttachmentItem
                      deleteInProgress={deleteInProgress}
                      onDeleteAttachment={onDeleteAttachment}
                      attachment={attachment}
                      updateInProgress={updateInProgress}
                      onUpdateAttachment={onUpdateAttachment}
                      item={item}
                      key={attachment.attachmentName + "_" + index}
                    />
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </AttachmentList>
      </Container>
    </>
  );
}
const Container = styled.div``;
const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  margin: 10px 0;
  max-width: 800px;
`;

const AddNewAttachment = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
  padding: 5px;
  margin-bottom: 20px;
`;
