import {
  Button,
  Dialog,
  DialogSurface,
  Divider,
  Text,
} from "@fluentui/react-components";
import { devices } from "library/constants";
import { IDocuments } from "library/types";
import { getExtensionFromUrl, openLinkInNewTab } from "library/utils";
import styled from "styled-components";

import { DocumentRegular } from "@fluentui/react-icons";
import { fileExtensionIcons } from "library/shared";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  documents: IDocuments[];
};

export function DocumentsDialog({ open, setOpen, documents, title }: Props) {
  return (
    <Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Text block align="center" weight="semibold" size={400}>
          {title}
        </Text>
        <StyledDivider />
        {documents?.map((doc) => {
          const docType = getExtensionFromUrl(doc.attachmentPath);

          return (
            <DocItemButton
              key={doc.attachmentName}
              onClick={() => {
                openLinkInNewTab(doc.attachmentPath || "");
              }}
              icon={fileExtensionIcons[docType] || <DocumentRegular />}
              appearance="subtle"
            >
              <Text key={doc.attachmentName}>{doc.attachmentName}</Text>
            </DocItemButton>
          );
        })}
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 600px;
    min-width: 600px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 10px 0;
`;

const DocItemButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  text-decoration: underline;
  width: 100%;
`;
