import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IDependent, IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateDependentData } from "./useGetDependentData";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  employeeDependentTag: number;
};

const deleteDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  employeeDependentTag,
}: Params) => {
  const response = await api.delete(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}/${employeeDependentTag}`
  );
  return response.data as IDependent;
};

export function useDeleteDependent(refetch = true) {
  const queryClient = useQueryClient();

  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateEmployeeData = useInvalidateEmployeeData();
  const invalidateDependentData = useInvalidateDependentData();

  return useMutation(deleteDependent, {
    mutationKey: MUTATION_KEYS.deleteDependent,
    onSuccess: (deletedDependent) => {
      if (refetch) {
        invalidateEmployeeData();
        invalidateDependentData();
      } else {
        const employeeQueryData = queryClient.getQueryData([
          QUERY_KEYS.employeeData,
          deletedDependent.countryCode,
          deletedDependent.clientInternalNumberGos,
        ]) as IEmployee;
        const employeeWithUpdatedDependentList = {
          ...employeeQueryData,
          dependents: employeeQueryData?.dependents?.filter((dependent) => {
            return (
              dependent.employeeDependentTag !==
              deletedDependent.employeeDependentTag
            );
          }),
        };
        queryClient.setQueriesData(
          [
            QUERY_KEYS.employeeData,
            deletedDependent.countryCode,
            deletedDependent.clientInternalNumberGos,
          ],
          employeeWithUpdatedDependentList
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
