import { Text } from "@fluentui/react-components";
import { GiftRegular } from "@fluentui/react-icons";
import { BenefitCard } from "components/BenefitCard/BenefitCard";
import { CardListWrap } from "components/styled";
import { isWithinInterval } from "date-fns";
import { useAtomValue, useSetAtom } from "jotai";
import {
  EApprovalStatus,
  EBenefitContentTab,
  ICardBenefitPackage,
} from "library/types";
import { Trans, useTranslation } from "react-i18next";
import {
  benefitContentTabAtom,
  selectedPackageUserAtom,
  showExpiredPackagesAtom,
} from "store/UIBenefitsPage";
import styled from "styled-components";

type Props = {
  data: ICardBenefitPackage[];
};
export function EnrolledPackages({ data }: Props) {
  const { t } = useTranslation();
  const showExpired = useAtomValue(showExpiredPackagesAtom);
  const setSelectedContentTab = useSetAtom(benefitContentTabAtom);

  const selectedPackageUser = useAtomValue(selectedPackageUserAtom);

  const filteredData = data?.filter((item) => {
    const expired = !isWithinInterval(new Date(), {
      start: new Date(item?.validFrom || ""),
      end: new Date(item?.validTo || ""),
    });
    if (!showExpired && expired) return false;

    if (item.isDependent) {
      return item.employeeDependentTag === selectedPackageUser?.value;
    }
    return item.employeeLoginEmailAddress === selectedPackageUser?.value;
  });

  return (
    <>
      {filteredData?.length ? (
        <CardListWrap>
          {filteredData.map((benefit) => {
            const hasPendingStatus =
              benefit?.cancelEnrollApprovalStatusCode ===
              EApprovalStatus.PENDING;
            const requiresCancellationApproval =
              benefit?.requiresCancellationApproval;
            const waitingForCancellation =
              requiresCancellationApproval &&
              !!benefit?.dateOfCancellation &&
              hasPendingStatus;

            return (
              <BenefitCard
                waitingForCancel={waitingForCancellation}
                isDependent={benefit.isDependent}
                selected
                key={benefit.benefitPackageTag}
                benefitPackage={benefit}
              />
            );
          })}
        </CardListWrap>
      ) : (
        <NoEnrolledBenefitsText>
          <div>
            <GiftRegular fontSize={25} />
          </div>
          <Text weight="semibold" block>
            <Trans
              t={t}
              i18nKey={"hbh.noEnrolledBenefits.label"}
              components={[
                <LinkToEligible
                  onClick={() => {
                    setSelectedContentTab(EBenefitContentTab.eligible);
                  }}
                />,
              ]}
            />
          </Text>
        </NoEnrolledBenefitsText>
      )}
    </>
  );
}

const NoEnrolledBenefitsText = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 10px;
  max-width: 600px;
`;

const LinkToEligible = styled.span`
  color: ${({ theme }) => theme.palette.green};
  text-decoration: underline;
  cursor: pointer;
`;
