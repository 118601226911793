export const ROUTES = {
  HOME: "/",
  PROFILE: "/profile",
  BENEFITS: "/benefits",
  OFFERINGS: "/offerings",
  UNAUTHORIZED_PAGE: "/unauthorized",
};

export const breakpoints = {
  xs: 320,
  xs2: 370,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const devices = {
  xs: `(min-width: ${breakpoints.xs}px)`,
  xs2: `(min-width: ${breakpoints.xs2}px)`,
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
  "2xl": `(min-width: ${breakpoints["2xl"]}px)`,
};

export const sizes = {
  appHeader: 50,
  appNavHeightMobile: 60,
  appNavHeightDesktop: 40,
};

export const consentLinksByCountries = {
  "Country.AT": [
    // "https://greco.services/wp-content/uploads/2022/05/ATEGHV_PRIVACY_NOTICE_CLIENTS.pdf",
    // "https://greco.services/wp-content/uploads/2022/03/ATGI_PRIVACY_NOTICE_CLIENTS_EN.pdf",
    // "https://greco.services/wp-content/uploads/2022/05/ATGI_PRIVACY_NOTICE_CLIENTS.pdf",
    // "https://greco.services/wp-content/uploads/2022/05/ATGIH_PRIVACY_NOTICE_CLIENTS_EN.pdf",
    // "https://greco.services/wp-content/uploads/2022/05/ATGIH_PRIVACY_NOTICE_CLIENTS.pdf",
    // "https://greco.services/wp-content/uploads/2022/05/ATGRE_PRIVACY_NOTICE_CLIENTS.pdf",
    "https://greco.services/wp-content/uploads/2022/05/ATGSP_PRIVACY_NOTICE_CLIENTS_EN.pdf",
    // "https://greco.services/wp-content/uploads/2022/05/ATGSP_PRIVACY_NOTICE_CLIENTS.pdf",
  ],
  "Country.BG": [
    "https://greco.services/wp-content/uploads/2022/05/BGG_PRIVACY_NOTICE_CLIENTS.pdf",
  ],
  "Country.CZ": [
    "https://greco.services/wp-content/uploads/2022/05/CZG_PRIVACY_NOTICE_CLIENTS.pdf",
  ],
  "Country.EE": [
    "https://greco.services/wp-content/uploads/2022/05/EEG_PRIVACY_NOTICE_CLIENTS.pdf",
    "https://greco.services/wp-content/uploads/2022/05/EEG_PRIVACY_NOTICE_CLIENTS_EN.pdf",
  ],
  "Country.HR": [
    "https://greco.services/wp-content/uploads/2022/05/HRG_PRIVACY_NOTICE_CLIENTS.pdf",
  ],
  "Country.LT": [
    "https://greco.services/wp-content/uploads/2024/01/LTG_data_protection_declaration_clients.pdf",
    "https://greco.services/wp-content/uploads/2023/05/2022-01-06-LTG-data_protection_declaration_clients-valid-from-2022-05-05-galutinis-ikelimui.pdf",
  ],
  "Country.PL": [
    "https://greco.services/wp-content/uploads/2022/05/PLG_PRIVACY_NOTICE_CLIENTS.pdf",
  ],
  "Country.RO": [
    "https://greco.services/wp-content/uploads/2022/05/ROGBC_PRIVACY_NOTICE_CLIENTS.pdf",
  ],
};
