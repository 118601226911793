import { DeleteRegular } from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppLogoSpinner } from "components/AppLogoSpinner/AppLogoSpinner";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { TextareaField } from "components/controls/TextareaField";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex, RemoveButton } from "components/styled";
import { Form, Formik } from "formik";
import { IAttachment, IEmployee } from "library/types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import * as yup from "yup";

type Props = {
  attachment: IAttachment;
  item: IEmployee;
  updateInProgress: boolean;
  deleteInProgress: boolean;
  onUpdateAttachment: ({
    attachment,
    newValues,
  }: {
    attachment: IAttachment;
    newValues: {
      attachmentName: string;
      attachmentSummary: string;
      attachmentAbstract: string;
      isHiddenForEmployee: boolean;
    };
  }) => void;
  onDeleteAttachment: (attachment: IAttachment) => void;
};

export function AttachmentItem({
  attachment,
  item,
  onUpdateAttachment,
  onDeleteAttachment,
  deleteInProgress,
  updateInProgress,
}: Props) {
  const { t } = useTranslation();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const validationSchema = yup.object().shape({
    attachmentName: yup.string().required(),
    attachmentSummary: yup.string().max(4000).nullable(),
    attachmentAbstract: yup.string().max(4000).nullable(),

    // .required("Must have friends") // these constraints are shown if and only if inner constraints are satisfied
    // .min(1, "Minimum of 3 friends"),
  });

  const initialValues = useMemo(() => {
    if (!attachment) return {};
    return {
      attachmentName: attachment.attachmentName,
      attachmentSummary: attachment.attachmentSummary,
      attachmentAbstract: attachment.attachmentAbstract,
    };
  }, [attachment]);

  const handleSubmit = (values) => {
    onUpdateAttachment({ attachment, newValues: values });
  };

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          dirty,

          setFieldValue,
          /* and other goodies */
        }) => {
          return (
            <>
              {(updateInProgress || deleteInProgress) && <AppLogoSpinner />}
              <Form>
                <Container>
                  <TextareaField
                    label={t("hbh.documentSummary.label")}
                    name={"attachmentSummary"}
                    value={values["attachmentSummary"] as string}
                    setFieldValue={setFieldValue}
                    error={errors["attachmentSummary"]}
                    rows={3}
                    resize="vertical"
                  />
                  <TextareaField
                    label={t("hbh.documentAbstract.label")}
                    name={"attachmentAbstract"}
                    value={values["attachmentAbstract"] as string}
                    setFieldValue={setFieldValue}
                    error={errors["attachmentAbstract"]}
                    rows={3}
                    resize="vertical"
                  />

                  <Flex $justify="space-between">
                    <RemoveButton
                      disabled={updateInProgress || deleteInProgress}
                      onClick={() => {
                        setDeleteConfirmationOpen(true);
                      }}
                      icon={<DeleteRegular />}
                    >
                      {t("hbh.deleteDocument.label")}
                    </RemoveButton>
                    <PrimaryButton
                      disabled={!dirty || updateInProgress || deleteInProgress}
                      icon={<SaveIcon />}
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {t("hbh.saveDocument.label")}
                    </PrimaryButton>
                  </Flex>
                </Container>
              </Form>
            </>
          );
        }}
      </Formik>
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={() => {
            onDeleteAttachment(attachment);
            toggleDeleteConfirmationState();
          }}
          description={t("hbh.deleteDocumentConfirm.label")}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deleteDocument.label")}: ${
            attachment.attachmentName
          } `}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  box-sizing: border-box;
  gap: 10px;
  padding: 10px;
`;
