import { Divider, webLightTheme } from "@fluentui/react-components";

import { Flex, Grid } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";

export function PaletteDemo() {
  const theme = useAppTheme();

  return (
    <div className="App">
      <div
        style={{
          width: "95%",
          margin: "50px auto",
        }}
      >
        <Grid gridColumns={7}>
          {Object.keys(theme.palette).map((item) => {
            return (
              <Flex>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    background: theme.palette[item],
                  }}
                />
                <div>{item}</div>
              </Flex>
            );
          })}
        </Grid>
        <br />
        <Divider
          appearance="strong"
          style={{
            margin: "30px 0",
          }}
        />
        <br />
        <Grid gridColumns={4}>
          {Object.keys(webLightTheme).map((item) => {
            return (
              <Flex>
                <div
                  style={{
                    width: 50,
                    height: 50,
                    background: webLightTheme[item],
                  }}
                />
                <div
                  style={{
                    fontSize: 10,
                  }}
                >
                  {item}
                </div>
              </Flex>
            );
          })}
        </Grid>
      </div>
    </div>
  );
}
