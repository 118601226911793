import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IDependent, IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  payload: IDependent;
};

const createDependent = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params) => {
  const response = await api.post(
    `/employee/dependent/${countryCode}/${clientInternalNumberGOS}`,
    payload
  );
  return response.data as IDependent;
};

export function useCreateDependent(refetch = true) {
  const queryClient = useQueryClient();

  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateEmployeeData = useInvalidateEmployeeData();
  return useMutation(createDependent, {
    mutationKey: MUTATION_KEYS.createDependent,
    onSuccess: (newDependent) => {
      if (refetch) {
        invalidateEmployeeData();
      } else {
        const employeeQueryData = queryClient.getQueryData([
          QUERY_KEYS.employeeData,
          newDependent.countryCode,
          newDependent.clientInternalNumberGos,
        ]) as IEmployee;

        const employeeWithUpdatedDependentList = {
          ...employeeQueryData,
          dependents: [...(employeeQueryData?.dependents || []), newDependent],
        };
        queryClient.setQueriesData(
          [
            QUERY_KEYS.employeeData,
            newDependent.countryCode,
            newDependent.clientInternalNumberGos,
          ],
          employeeWithUpdatedDependentList
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
