import {
  Button,
  Checkbox,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Text,
} from "@fluentui/react-components";
import {
  ErrorMessageContainer,
  Flex,
  Grid,
  StyledErrorMessage,
} from "components/styled";
import { useFormikContext } from "formik";
import { useAppTheme } from "hooks/useAppTheme";
import { useTranslation } from "react-i18next";
import { useGetConsentLink } from "./hooks/useGetConsentLink";

export function MainConsentField() {
  const { t } = useTranslation();

  const theme = useAppTheme();
  const consentLink = useGetConsentLink();
  const { values, errors, setFieldValue } = useFormikContext<FormData>();
  return (
    <Grid gridColumns={1}>
      <Flex align="center">
        <Checkbox
          name={"isConsentGiven"}
          checked={values["isConsentGiven"]}
          onChange={(ev, data) => {
            setFieldValue("isConsentGiven", !!data.checked);
          }}
          label={""}
        />
        <Flex align="baseline">
          <Popover>
            <PopoverTrigger disableButtonEnhancement>
              <Button
                appearance="transparent"
                style={{
                  padding: 0,
                  color: theme.semanticColors.link,
                }}
              >
                {t("hbh.iAgreeToConsentText.label")}
              </Button>
            </PopoverTrigger>
            <PopoverSurface
              tabIndex={-1}
              style={{
                maxWidth: "600px",
              }}
            >
              <Text>{consentLink}</Text>
            </PopoverSurface>
          </Popover>
        </Flex>
      </Flex>
      <ErrorMessageContainer>
        <StyledErrorMessage>{errors["isConsentGiven"]}</StyledErrorMessage>
      </ErrorMessageContainer>
    </Grid>
  );
}
