import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployeeEnrollment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeEnrollments } from "./useGetEnrollments";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeDependentTag?: number;
  dateOfCancellation: string;
  payload: {
    reasonForCancellation: string;
  };
};

const cancelEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeDependentTag = 0,
  dateOfCancellation,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/cancel/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeDependentTag}/${employeeEnrollmentTag}/${dateOfCancellation}`,
    payload
  );

  return response.data as IEmployeeEnrollment;
};

export function useCancelEnrolment(refetch = true) {
  const queryClient = useQueryClient();

  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  const invalidateEmployeeEnrollments = useInvalidateEmployeeEnrollments();
  return useMutation(cancelEnrollment, {
    mutationKey: MUTATION_KEYS.cancelEnrollment,
    onSuccess: (canceledEnrollment) => {
      if (refetch) {
        invalidateEmployeeEnrollments();
      } else {
        const employeeEnrollments = queryClient.getQueryData<
          IEmployeeEnrollment[]
        >([
          QUERY_KEYS.employeeEnrollments,
          canceledEnrollment.countryCode,
          canceledEnrollment.clientInternalNumberGos,
        ]);

        const updatedEmployeeEnrollments = employeeEnrollments?.map(
          (employeeEnrollment) => {
            if (
              employeeEnrollment.employeeEnrollmentTag ===
              canceledEnrollment.employeeEnrollmentTag
            ) {
              return canceledEnrollment;
            }
            return employeeEnrollment;
          }
        );

        queryClient.setQueriesData(
          [
            QUERY_KEYS.employeeEnrollments,
            canceledEnrollment.countryCode,
            canceledEnrollment.clientInternalNumberGos,
          ],
          updatedEmployeeEnrollments
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
