import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IAttachment, IEmployeeEnrollment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeEnrollments } from "./useGetEnrollments";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeDependentTag?: number;
  dateOfEnrollment: string;
  payload: {
    attachments: IAttachment[];
  };
};

const enrollPackage = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeDependentTag = 0,
  dateOfEnrollment,
  payload,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/enroll/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeDependentTag}/${employeeEnrollmentTag}/${dateOfEnrollment}`,
    payload
  );

  return response.data as IEmployeeEnrollment;
};

export function useEnrollPackage(refetch = true) {
  const queryClient = useQueryClient();
  const invalidateEmployeeEnrollments = useInvalidateEmployeeEnrollments();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(enrollPackage, {
    mutationKey: MUTATION_KEYS.enrollPackage,
    onSuccess: (newEnrollment) => {
      if (refetch) {
        invalidateEmployeeEnrollments();
      } else {
        const employeeEnrollments = queryClient.getQueryData<
          IEmployeeEnrollment[]
        >([
          QUERY_KEYS.employeeEnrollments,
          newEnrollment.countryCode,
          newEnrollment.clientInternalNumberGos,
        ]);
        const updatedEmployeeEnrollments = employeeEnrollments?.map(
          (employeeEnrollment) => {
            if (
              employeeEnrollment.employeeEnrollmentTag ===
              newEnrollment.employeeEnrollmentTag
            ) {
              return newEnrollment;
            }
            return employeeEnrollment;
          }
        );

        queryClient.setQueriesData(
          [
            QUERY_KEYS.employeeEnrollments,
            newEnrollment.countryCode,
            newEnrollment.clientInternalNumberGos,
          ],
          updatedEmployeeEnrollments
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
