import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./quill-style.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { FallbackSpinner } from "components/FallbackSpinner/FallbackSpinner";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { checkIsLoggedIn, msal } from "service/auth";
import { requestPermission } from "library/utils";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const appId = process.env.REACT_APP_CLIENT_ID!;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      // staleTime: Infinity,
      // staleTime: 0,
    },
  },
});

const startApplication = () => {
  root.render(
    <React.Fragment>
      <Suspense fallback={<FallbackSpinner />}>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    </React.Fragment>
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const fn = async () => {
  const isLoggedIn = await checkIsLoggedIn();
  if (isLoggedIn) {
    startApplication();
  } else {
    msal?.loginRedirect({
      scopes: [appId],
    });
  }
};
fn();

requestPermission();
