import styled from "styled-components";

import parse from "html-react-parser";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { selectedClientAtom } from "store";

export function HomePage() {
  const selectedClient = useAtomValue(selectedClientAtom);

  const html = selectedClient?.welcomePageText || ``;

  const options = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }

      if (attribs.src) {
        return (
          <img
            src={attribs.src}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
            alt="some-text"
          />
        );
      }
    },
  } as any;
  return (
    <Container>
      <Content className="ql-editor">
        {parse(html, options)}
        {/* <img width={"100%"} src={LandingPageLogo} alt="landing-page-logo" /> */}
      </Content>
    </Container>
  );
}

const Container = styled.div``;
const Content = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  padding-top: 30px;

  @media only screen and ${devices.md} {
    padding-top: 50px;
  }
`;
