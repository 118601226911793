import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useGetEmployeeEnrollments } from "state/useGetEnrollments";
import { selectedClientAtom } from "store";
import useEmployee from "./useEmployee";

export function useAllUserDocumentsForChatbot(): string[] | null {
  const selectedClient = useAtomValue(selectedClientAtom);
  const { employeeData, employeeDataLoading } = useEmployee();

  const { data: employeeEnrollments, isLoading: enrollmentsLoading } =
    useGetEmployeeEnrollments({
      clientInternalNumberGos: Number(selectedClient?.clientInternalNumberGos),
      countryCode: Number(selectedClient?.countryCode),
      enabled: !!selectedClient,
    });

  const allDocuments = useMemo(() => {
    const documents = [] as any;
    if (!employeeData || !employeeEnrollments) return null;
    employeeEnrollments.forEach((enrollment) => {
      const enrollmentDocuments = enrollment?.attachments || [];
      enrollmentDocuments.forEach((document) => {
        if (documents.includes(document.attachmentPath)) return;

        documents.push(document.attachmentPath);
      });
    });
    (selectedClient?.attachments || []).forEach((attachment) => {
      if (documents.includes(attachment.attachmentPath)) return;

      documents.push(attachment.attachmentPath);
    });
    (employeeData?.attachments || []).forEach((attachment) => {
      if (documents.includes(attachment.attachmentPath)) return;

      documents.push(attachment.attachmentPath);
    });
    return documents;
  }, [employeeData, employeeEnrollments, selectedClient]);

  if (enrollmentsLoading || employeeDataLoading) return null;

  return allDocuments;
}
