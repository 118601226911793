// /benefitcategory/attachment/{countryCode}/{clientInternalNumberGos}/{benefitCategoryTag}:
import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IAttachment, IEmployee } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeData } from "./useGetEmployeeData";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  payload: IAttachment;
};

const createEmployeeAttachment = async ({
  countryCode,
  clientInternalNumberGOS,
  payload,
}: Params) => {
  const response = await api.post(
    `/employee/attachment/${countryCode}/${clientInternalNumberGOS}`,
    payload
  );

  return response.data as IAttachment;
};

export function useCreateEmployeeAttachment(refetch = true) {
  const queryClient = useQueryClient();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();
  const invalidateEmployee = useInvalidateEmployeeData();
  return useMutation(createEmployeeAttachment, {
    mutationKey: MUTATION_KEYS.createEmployeeAttachment,
    onSuccess: (newAttachment) => {
      if (refetch) {
        invalidateEmployee();
      } else {
        const previousEmployeeData = queryClient.getQueryData<IEmployee>([
          QUERY_KEYS.employeeData,
          newAttachment?.countryCode?.toString(),
          newAttachment?.clientInternalNumberGos?.toString(),
        ]);

        const updatedEmployeeData = {
          ...previousEmployeeData,
          attachments: [
            ...(previousEmployeeData?.attachments || []),
            newAttachment,
          ],
        };

        queryClient.setQueryData(
          [
            QUERY_KEYS.employeeData,
            newAttachment?.countryCode?.toString(),
            newAttachment?.clientInternalNumberGos?.toString(),
          ],
          updatedEmployeeData
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
