import {
  Button,
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";
import styled from "styled-components";
import HbhLogo from "../../assets/H&B_logo.png";

export function AppLogoSpinner() {
  return (
    <Dialog modalType="modal" open={true}>
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle
          style={{
            display: "none", //fluent requires at list one focusable element in dialog surface body
          }}
          action={
            <DialogTrigger action="close">
              <Button appearance="subtle" aria-label="close" />
            </DialogTrigger>
          }
        />

        <Container>
          <Content>
            <div className="pulse-container">
              <div className="pulse"></div>
              <img src={HbhLogo} alt="App Logo" className="logo" />
            </div>
          </Content>
        </Container>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  background-color: ${({ theme }) =>
    theme.palette?.blackTranslucent40} !important;
  width: 100%;
  max-width: 100% !important;
  height: 100vh;
  backdrop-filter: blur(1px);
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 100%;

  /* Pulsating logo container */
  .pulse-container {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Pulsating circle effect */
  .pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.themeLighter};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    animation: pulseEffect 2s infinite;
  }

  /* App logo */
  .logo {
    z-index: 2;
    animation: scaleEffect 2s ease-in infinite;
  }

  /* Keyframes for pulsating animation */
  @keyframes pulseEffect {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
      opacity: 0;
    }
  }

  @keyframes scaleEffect {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }
`;
/* HTML: <div class="loader"></div> */
