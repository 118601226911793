import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { IEmployee, IEmployeeEnrollment } from "library/types";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";
import { useInvalidateEmployeeEnrollments } from "./useGetEnrollments";

type Params = {
  countryCode: number;
  clientInternalNumberGOS: number;
  benefitPackageTag: number;
  employeeEnrollmentTag: number;
  employeeDependentTag?: number;
  dateOfEnrollment: string;
};

const upgradeEnrollment = async ({
  countryCode,
  clientInternalNumberGOS,
  benefitPackageTag,
  employeeEnrollmentTag,
  employeeDependentTag = 0,
  dateOfEnrollment,
}: Params) => {
  const response = await api.patch(
    `/employeeenrollment/upgradeenrollment/${countryCode}/${clientInternalNumberGOS}/${benefitPackageTag}/${employeeDependentTag}/${employeeEnrollmentTag}/${dateOfEnrollment}`
  );

  return response.data as IEmployeeEnrollment[];
};

export function useUpgradeEnrollment(refetch = true) {
  const queryClient = useQueryClient();

  const invalidateEmployeeEnrollments = useInvalidateEmployeeEnrollments();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(upgradeEnrollment, {
    mutationKey: MUTATION_KEYS.upgradeEnrollment,
    onSuccess: (upgradeEnrollments) => {
      if (refetch) {
        invalidateEmployeeEnrollments();
      } else {
        const employeeEnrollments = queryClient.getQueryData<
          IEmployeeEnrollment[]
        >([
          QUERY_KEYS.employeeEnrollments,
          upgradeEnrollments[0].countryCode,
          upgradeEnrollments[0].clientInternalNumberGos,
        ]);

        const updatedEmployeeEnrollments = employeeEnrollments?.map(
          (employeeEnrollment) => {
            const enrollToUpdate = upgradeEnrollments.find(
              (enr) =>
                enr.employeeEnrollmentTag ===
                employeeEnrollment.employeeEnrollmentTag
            );
            if (enrollToUpdate) {
              return enrollToUpdate;
            }
            return employeeEnrollment;
          }
        );
        queryClient.setQueriesData(
          [
            QUERY_KEYS.employeeEnrollments,
            upgradeEnrollments[0].countryCode,
            upgradeEnrollments[0].clientInternalNumberGos,
          ],
          updatedEmployeeEnrollments
        );
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
