import { Button } from "@fluentui/react-components";
import {
  DeleteRegular,
  EyeTrackingOffRegular,
  EyeTrackingRegular,
} from "@fluentui/react-icons";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { Flex } from "components/styled";
import { UploadFileList } from "components/UploadFileList";
import { useFormikContext } from "formik";
import { genUUID } from "library/utils";
import styled from "styled-components";

export function UploadConsentDocuments() {
  const { values, setFieldValue } = useFormikContext<FormData>();
  const documentList = values["scannedSignedConsentDocument"] || [];
  return (
    <div>
      <AddNewAttachmentList>
        <UploadFileList
          selectedFileList={documentList?.map((attach) => ({
            name: attach.attachmentName,
            base64String: attach.attachmentContent,
            type: attach.attachmentMimeType,
          }))}
          setFieldValue={(_, uploadedList) => {
            const updatedAttachments = [
              ...documentList,
              ...(uploadedList || [])?.map((newAttach) => ({
                attachmentName: newAttach?.name,
                attachmentContent: newAttach?.base64String || "",
                attachmentMimeType: newAttach?.type || "",
              })),
            ];
            setFieldValue("scannedSignedConsentDocument", updatedAttachments);
          }}
          fieldName=""
          label=""
        />
      </AddNewAttachmentList>

      <AttachmentList>
        {documentList?.map((attachment) => {
          return (
            <AttachmentItem key={genUUID()}>
              <div>{attachment?.attachmentName}</div>
              <Flex wrap="nowrap">
                <Button
                  onClick={() => {
                    const filteredAttachmentList = documentList.filter(
                      (attach) => {
                        return (
                          attach.attachmentName !== attachment?.attachmentName
                        );
                      }
                    );
                    setFieldValue(
                      "scannedSignedConsentDocument",
                      filteredAttachmentList
                    );
                  }}
                  icon={<DeleteRegular />}
                />
              </Flex>
            </AttachmentItem>
          );
        })}
      </AttachmentList>
    </div>
  );
}

const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  max-height: 300px;
  overflow-y: auto;
`;

const AttachmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;
const AddNewAttachmentList = styled.div`
  display: flex;
  width: fit-content;
  padding: 5px;
  margin-top: 10px;
`;
